import {
  Root,
  Trigger,
  Content,
  Arrow,
  Provider,
} from "@radix-ui/react-tooltip"

interface TooltipProps {
  content: React.ReactNode
  position?: "top" | "right" | "bottom" | "left"
  delayDuration?: number
  children: React.ReactNode
}

export const Tooltip = ({
  content,
  position = "top",
  delayDuration = 100,
  children,
}: TooltipProps) => {
  return (
    <Provider delayDuration={delayDuration}>
      <Root>
        <Trigger>{children}</Trigger>
        <Content
          side={position}
          sideOffset={5}
          className="z-20 transform-gpu rounded-lg bg-white p-2 text-paragraph-small text-black shadow-primary transition-transform"
        >
          {content}
          <Arrow className="fill-current text-white" />
        </Content>
      </Root>
    </Provider>
  )
}
