import { lazy, Suspense, useMemo } from "react"
import { useMutation } from "@tanstack/react-query"
import { useForm } from "@tanstack/react-form"
import { zodValidator } from "@tanstack/zod-form-adapter"
import { t, Trans } from "@lingui/macro"
import { z } from "zod"
import { useNavigate } from "@tanstack/react-router"

import { useCommunityId } from "@src/hooks/useCommunityId"
import {
  saveApplicationForm,
  FormDto,
  EditorFormQuestion,
  EditorFormDto,
  CommunityForm,
} from "@src/api/form"
import {
  GenericForm,
  ZodValidator,
} from "@src/components/organisms/GenericForm"
import {
  prepareFormSaveStep1,
  prepareFormSaveStep2,
} from "@src/components/organisms/GenericForm/parser"
import { Skeleton } from "@src/components/atoms/Skeleton"
import { Input } from "@src/components/atoms/Input"
import { Button } from "@src/components/atoms/Button"

const Editor = lazy(() =>
  import("@src/components/organisms/Editor").then((module) => ({
    default: module.Editor,
  })),
)

export type CommunityFormWithConditionalOrder = Omit<FormDto, "questions"> & {
  questions?: EditorFormQuestion[] | null
}

export type FormPageProps = {
  form?: CommunityForm
  viewMode?: boolean
  duplicate?: boolean
}

export const FormForm = ({
  form,
  viewMode = false,
  duplicate = false,
}: FormPageProps) => {
  const navigate = useNavigate()
  const communityId = useCommunityId()

  const validators = {
    name: {
      onBlur: z
        .string()
        .min(3, t`The form name must be at least 3 characters`)
        .max(80, t`The form name must be under 80 characters`),
    },
  }

  const questionsWithConditionalOrder: EditorFormDto[] | null | undefined =
    form?.questions?.map((question) => {
      if (!question?.formQuestionConditionalLogicList?.length) {
        return question
      }

      const { formQuestionConditionalLogicList } = question
      const conditionalLogicListWithOrder =
        formQuestionConditionalLogicList.map((conditionalLogic) => {
          return {
            ...conditionalLogic,
            temporaryQuestionId:
              form?.questions?.find(
                (question) =>
                  question.id === conditionalLogic.conditionFormQuestionId,
              )?.questionOrder || "",
          }
        })

      return {
        ...question,
        formQuestionConditionalLogicList: conditionalLogicListWithOrder,
      }
    })

  const formWithConditionalOrder: CommunityFormWithConditionalOrder = useMemo(
    () => ({
      ...form,
      questions: questionsWithConditionalOrder,
    }),
    [form, questionsWithConditionalOrder],
  )

  const { mutate: saveApplicationFormMutation } = useMutation({
    mutationFn: (payload: CommunityForm) => {
      if (duplicate) {
        // reset all ids to 0
        return saveApplicationForm({
          ...payload,
          id: 0,
          questions: payload.questions?.map((question) => ({
            ...question,
            id: 0,
            questionOptions: question.questionOptions?.map((option) => ({
              ...option,
              id: 0,
            })),
          })),
        })
      } else {
        return saveApplicationForm(payload, form?.id)
      }
    },
  })

  const formInstance = useForm<EditorFormDto, ZodValidator>({
    defaultValues: formWithConditionalOrder,
    validatorAdapter: zodValidator(),
    onSubmit: async ({ value }) => {
      const formStep1 = prepareFormSaveStep1(value)

      return saveApplicationFormMutation(formStep1, {
        onSuccess: (responseStep1) => {
          const formStep2 = prepareFormSaveStep2(value, responseStep1)

          return saveApplicationFormMutation(formStep2, {
            onSuccess: () => {
              if (form?.id || duplicate) {
                navigate({
                  to: "/community/$communityId/admin-panel/forms/$formId",
                  params: {
                    communityId: String(communityId),
                    formId: String(responseStep1.id),
                  },
                  search: { view_mode: false, duplicate: false },
                })
              }
            },
          })
        },
      })
    },
  })

  return formWithConditionalOrder ? (
    <form
      className="m-auto"
      onSubmit={(e) => {
        e.preventDefault()
        e.stopPropagation()

        formInstance.handleSubmit()
      }}
    >
      <div className="flex flex-col gap-6">
        <header className="flex items-center justify-end gap-2">
          <Button
            variant="secondary"
            small
            onClick={() => {
              navigate({
                to: "/community/$communityId/admin-panel/forms",
                params: {
                  communityId: String(communityId),
                },
              })
            }}
          >
            <Trans>Cancel</Trans>
          </Button>
          <Button
            variant="ternary"
            small
            onClick={() => {
              navigate({
                to: "/community/$communityId/admin-panel/forms/$formId",
                params: {
                  communityId: String(communityId),
                  formId: String(form?.id),
                },
                search: { view_mode: !viewMode, duplicate: false },
              })
            }}
          >
            {viewMode ? <Trans>Edit</Trans> : <Trans>Preview</Trans>}
          </Button>
          <formInstance.Subscribe
            selector={(state) => [state.canSubmit, state.isSubmitting]}
            children={([canSubmit, isSubmitting]) => (
              <Button small type="submit" disabled={!canSubmit || isSubmitting}>
                <Trans>Save Changes</Trans>
              </Button>
            )}
          />
        </header>
        <section className="w-full rounded-xl border border-pearl-lighter bg-white p-6">
          <formInstance.Field
            name="name"
            validators={validators.name}
            children={(field) => (
              <Input
                name="name"
                placeholder={t`Name`}
                error={field.state.meta.errors.join(", ")}
                variant="ternary"
                onChange={(value) => field.handleChange(value as string)}
                value={field.state.value}
                onBlur={field.handleBlur}
              />
            )}
          />
          <div className="my-4 h-[1px] w-full bg-pearl-lighter"></div>
          <Suspense fallback={<div>Loading editor...</div>}>
            <formInstance.Field
              name="description"
              children={(field) => (
                <Editor
                  className="rounded-xl bg-ghost-white"
                  placeholder={t`Write a compelling description to explain to users how they should dill the form`}
                  value={field.state.value}
                  onChange={(value) => {
                    field.handleChange(value)
                  }}
                />
              )}
            />
          </Suspense>
          <div className="my-4 h-[1px] w-full bg-pearl-lighter"></div>
          <GenericForm form={formInstance} viewMode={viewMode} />
        </section>
      </div>
    </form>
  ) : (
    <Skeleton className="h-[100vh]" />
  )
}
