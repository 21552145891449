import {
  Button as HeadlessButton,
  type ButtonProps as HeadlessButtonProps,
} from "@headlessui/react"
import clsx from "clsx"
import React from "react"
import { Link, LinkOptions } from "@tanstack/react-router"

const colors = {
  default: "text-gray-dark bg-ghost-white border-gray-dark border",
  yellow: "text-yellow-600 bg-amber-200 border border-amber-300",
  green: "text-green bg-green-light border border-green",
  rose: "text-rose-500 bg-rose-200 border border-rose-300",
}

export type BadgeProps = { color?: keyof typeof colors }

export function Badge({
  color = "default",
  className,
  ...props
}: BadgeProps & React.ComponentPropsWithoutRef<"span">) {
  return (
    <span
      {...props}
      className={clsx(
        className,
        "inline-flex items-center rounded-md px-2 py-1 text-label",
        colors[color],
      )}
    />
  )
}

export const BadgeButton = ({
  color = "default",
  className,
  children,
  to,
  ...props
}: BadgeProps & {
  children: React.ReactNode
  to?: LinkOptions["to"]
} & HeadlessButtonProps) => {
  const classes = clsx(
    className,
    "data-[focus]:outline-blue-500 group relative inline-flex rounded-md focus:outline-none data-[focus]:outline data-[focus]:outline-2 data-[focus]:outline-offset-2",
  )

  return to ? (
    <Link className={classes} to={to}>
      <Badge color={color}>{children}</Badge>
    </Link>
  ) : (
    <HeadlessButton {...props} className={classes}>
      <Badge color={color}>{children}</Badge>
    </HeadlessButton>
  )
}
