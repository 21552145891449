import { forwardRef, useImperativeHandle } from "react"
import { useForm, Validator } from "@tanstack/react-form"
import { z } from "zod"
import { zodValidator } from "@tanstack/zod-form-adapter"

import { FormDto, FormAnswerDto } from "@src/api/form"
import { GenericFormViewerQuestion } from "./Question"

export type ZodValidator = Validator<
  unknown,
  z.ZodType<unknown, z.ZodTypeDef, unknown>
>

type GenericFormViewerProps = {
  formDefinition: FormDto
  defaultAnswers?: { answers: FormAnswerDto[] }
  onSave?: (form: { answers: FormAnswerDto[] }) => void
}

export const GenericFormViewer = forwardRef(
  ({ formDefinition, defaultAnswers, onSave }: GenericFormViewerProps, ref) => {
    const form = useForm<{ answers: FormAnswerDto[] }, ZodValidator>({
      defaultValues: defaultAnswers,
      validatorAdapter: zodValidator(),
      onSubmit: async ({ value }) => {
        onSave?.({
          ...value,
          answers: Object.values(value.answers),
        })
      },
    })

    // Expose the `handleSubmit` method through the ref
    useImperativeHandle(ref, () => ({
      handleSubmit: () => {
        form.handleSubmit()
      },
    }))

    return (
      <form
        className="w-full"
        role="form"
        onSubmit={(e) => {
          e.preventDefault()
          e.stopPropagation()
          form.handleSubmit()
        }}
      >
        <section className="flex w-full flex-col">
          <div className="flex flex-col gap-6">
            {formDefinition.questions?.map((question, i) => (
              <GenericFormViewerQuestion
                key={i}
                question={question}
                questions={formDefinition.questions ?? []}
                form={form}
              />
            ))}
          </div>
        </section>
      </form>
    )
  },
)
