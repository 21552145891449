import { ReactNode } from "react"
import {
  Dialog as HeadlessDialog,
  DialogPanel,
  DialogTitle,
  DialogBackdrop,
} from "@headlessui/react"
import { clsx } from "clsx"

import { IconButton } from "@src/components/atoms/IconButton"

export type DialogProps = {
  title?: string
  open: boolean
  onClose: () => void
  children: ReactNode
  actions?: ReactNode
}

export const Dialog = ({
  title,
  open,
  onClose,
  children,
  actions,
}: DialogProps) => {
  return (
    <HeadlessDialog
      open={open}
      onClose={onClose}
      className="fixed inset-0 z-50"
    >
      <DialogBackdrop className="fixed inset-0 bg-black/30 backdrop-blur-sm" />

      <DialogPanel
        className={clsx([
          "fixed left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2",
          "w-1/2 rounded-xl bg-white shadow-lg",
        ])}
      >
        {title && (
          <DialogTitle className="flex items-center justify-between border-b border-pearl-lighter p-4 text-paragraph text-pearl-light">
            {title}
          </DialogTitle>
        )}

        <IconButton
          icon="close"
          onClick={onClose}
          className="absolute right-4 top-4 z-10 text-paragraph"
          variant="ternary"
        />

        <div>
          {children && <div className="p-4">{children}</div>}
          {actions && (
            <div className="bg-pearl-lighter/15 px-4 py-2">{actions}</div>
          )}
        </div>
      </DialogPanel>
    </HeadlessDialog>
  )
}
