import { useState } from "react"
import { Trans } from "@lingui/macro"

import { Input } from "@src/components/atoms/Input"
import { Label, Field } from "@src/components/atoms/Fieldset"
import { Button } from "@src/components/atoms/Button"
import { FormQuestionOptionDto } from "@src/api/form"

import { AnswerProps } from "./types"

const generateKey = (first = 0, second = 0, third = 0) =>
  parseInt(`${first}${second}${third}`, 10)

export const TableAnswer = ({ form, question }: AnswerProps) => {
  const [rows, setRows] = useState([0])
  const questionOrder = question?.questionOrder ?? 0

  const handleChange = (
    row: number,
    option: FormQuestionOptionDto,
    value: string,
  ) => {
    const key = generateKey(questionOrder, option?.questionOptionOrder, row)
    const currentAnswer = form.state.values.answers?.[key] ?? {}

    form.setFieldValue("answers", {
      ...form.state.values.answers,
      [key]: {
        ...currentAnswer,
        answer: value,
        optionAnswerOrder: option.questionOptionOrder,
        optionRowAnswerOrder: row,
        formQuestionId: question.id,
      },
    })
  }

  return (
    <Field className="flex-col">
      <Label
        htmlFor={question.title || ""}
        required={question.mandatoryAnswer}
        description={question.description || ""}
      >
        {question.title}
      </Label>

      <div className="flex flex-col gap-2">
        {rows.map((row) => (
          <div className="flex gap-2" key={row}>
            {question?.questionOptions?.map((option) => {
              const key = generateKey(
                questionOrder,
                option?.questionOptionOrder,
                row,
              )

              return (
                <form.Field
                  key={option.questionOptionOrder}
                  name={`answers[${key}].answer`}
                >
                  {(field) => (
                    <Input
                      name={`answers[${key}].answer`}
                      error={field.state.meta.errors.join(", ")}
                      placeholder={option.questionOption || ""}
                      onChange={(value) =>
                        handleChange(row, option, value as string)
                      }
                      value={field.state.value || ""}
                      onBlur={field.handleBlur}
                    />
                  )}
                </form.Field>
              )
            })}
          </div>
        ))}

        <Button
          variant="text"
          small
          className="self-start"
          onClick={() => setRows([...rows, rows.length])}
        >
          <Trans>Add row</Trans>
        </Button>
      </div>
    </Field>
  )
}
