export enum ApiDynamicFilterType {
  IsAnyOf,
  IsNoneOf,
}

export type ApiDynamicFilter<T extends string | number> = {
  filterType?: ApiDynamicFilterType
  values?: T[] | null
}

export type QuestionFilter = {
  question: string
  answers: string[]
}

export type QuestionDynamicFilter = {
  question: string
  answers: string[]
  filterType: ApiDynamicFilterType
}

/**
 * This is an impossible type to represent in TypeScript, since TypeScript does not support negation
 * of types. So "filters" is actually of type ApiDynamicFilter | string | number | null | undefined
 * We're just keeping the property name for documentation purposes. Unfortunately it's impossible to
 * create a general type that can be applied to all filters. An alternative solution could be to make
 * this type a union of all the possible filters so we can use it for the Filters component and correctly
 * type match the search params
 */
export type ApiFilters = {
  orderBy?: number
  skip?: number
  take?: number
  filters?: Array<QuestionFilter | QuestionDynamicFilter> | null
} & {
  [key: string]:
    | ApiDynamicFilter<string>
    | ApiDynamicFilter<number>
    | string
    | number
    | null
}
