import { Checkbox } from "@src/components/atoms/Checkbox"
import { Label, Field } from "@src/components/atoms/Fieldset"
import { AnswerProps } from "./types"

export const CheckboxAnswer = ({ form, question }: AnswerProps) => {
  const generateKey = (questionOrder: number = 0, optionOrder: number = 1) =>
    parseInt(`${questionOrder}${optionOrder}`, 10)

  const handleCheckboxChange = (
    isChecked: boolean,
    key: number,
    optionOrder: number | undefined,
  ) => {
    if (optionOrder == null) return

    const currentAnswer = form.state.values.answers?.[key] || {}

    form.setFieldValue("answers", {
      ...form.state.values.answers,
      [key]: isChecked
        ? {
            ...currentAnswer,
            optionAnswerOrder: optionOrder,
            formQuestionId: question.id,
          }
        : {},
    })
  }

  return (
    <Field className="flex-col">
      <Label
        htmlFor={question.title || ""}
        required={question.mandatoryAnswer}
        description={question.description || ""}
      >
        {question.title}
      </Label>

      {question?.questionOptions?.map((option) => {
        if (option.questionOptionOrder == null) {
          return null
        }

        const key = generateKey(
          question.questionOrder,
          option.questionOptionOrder,
        )

        return (
          <form.Field key={key} name={`answers[${key}].optionAnswerOrder`}>
            {(field) => (
              <Checkbox
                checked={field.state.value !== undefined}
                onChange={(isChecked) =>
                  handleCheckboxChange(
                    isChecked,
                    key,
                    option.questionOptionOrder,
                  )
                }
                label={option.questionOption || ""}
              />
            )}
          </form.Field>
        )
      })}
    </Field>
  )
}
