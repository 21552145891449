import { Fragment } from "react"
import {
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
} from "@headlessui/react"
import clsx from "clsx"

import {
  commonStyles,
  FieldProps,
  OptionProps,
} from "@src/components/atoms/Fieldset/common"

import { Field, Label, ErrorMessage } from "@src/components/atoms/Fieldset"
import { Icon } from "@src/components/atoms/Icon"

export type SelectProps<T extends number | string> = FieldProps & {
  options: OptionProps<T>[]
  value?: OptionProps<T> | null
  onSelect: (option: OptionProps<T>) => void
}

export const Select = <T extends string | number>({
  variant = "primary",
  label,
  insideLabel,
  description,
  info,
  placeholder,
  error,
  value,
  small = false,
  required = false,
  options,
  className,
  onSelect,
  onBlur,
  disabled,
}: SelectProps<T>) => {
  return (
    <Field className={clsx("flex-col", className)}>
      <Label
        htmlFor={label}
        required={required}
        description={description}
        info={info}
      >
        {label}
      </Label>

      <Listbox
        value={value}
        onChange={onSelect}
        disabled={disabled}
        invalid={!!error}
      >
        <ListboxButton
          className={clsx(
            commonStyles.base,
            commonStyles[variant],
            small ? ["gap-2 py-1 pl-3 pr-1"] : ["gap-4 py-2 pl-5 pr-2"],
          )}
        >
          <span className="flex items-center">
            {insideLabel && (
              <span className="mr-4 border-r border-pearl-light pr-4 font-semibold text-black">
                {insideLabel}
              </span>
            )}
            {value ? (
              value.label
            ) : (
              <span className="text-gray">{placeholder}</span>
            )}
          </span>
          <Icon
            className="group pointer-events-none text-black"
            icon="keyboard_arrow_down"
            size="large"
          />
        </ListboxButton>

        <ListboxOptions
          anchor="bottom"
          transition
          className={clsx(commonStyles.options)}
          onBlur={onBlur}
        >
          {options.map((option, index) => (
            <Fragment key={option.id}>
              {index > 0 && options[index - 1].group !== option.group && (
                <hr className="mx-4 my-1 border-t border-pearl-lighter" />
              )}
              <ListboxOption
                value={option}
                className={clsx(
                  commonStyles.option,
                  option.id === value?.id && "bg-blue-light/50",
                )}
              >
                {option.icon && (
                  <Icon
                    className="text-black"
                    icon={option.icon}
                    size="large"
                  />
                )}
                {option.label}
              </ListboxOption>
            </Fragment>
          ))}
        </ListboxOptions>
      </Listbox>
      <ErrorMessage>{error}</ErrorMessage>
    </Field>
  )
}
