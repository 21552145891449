import { useRef } from "react"
import clsx from "clsx"
import { useParams } from "@tanstack/react-router"
import { useQuery, useMutation } from "@tanstack/react-query"
import { Trans } from "@lingui/macro"

import { Button } from "@src/components/atoms/Button"
import { Image } from "@src/components/atoms/Image"
import { Countdown } from "@src/components/atoms/Countdown"
import { GenericFormViewer } from "@src/components/organisms/GenericFormViewer"

import { useCommunityId } from "@src/hooks/useCommunityId"
import {
  SurveyStatus,
  getSurveyDefinitionQuery,
  getSurveyResponseQuery,
  submitSurvey,
} from "@src/api/survey"
import { getCommunityOverviewQuery } from "@src/api/community"
import { getApplicationFormQuery } from "@src/api/form"

export const SurveysCompletePage = () => {
  const formRef = useRef<{ handleSubmit: () => void }>(null)
  const communityId = useCommunityId()

  const params = useParams({ strict: false })
  const surveyId = params.surveyId ? parseInt(params.surveyId, 10) : undefined

  const { data: survey } = useQuery(getSurveyDefinitionQuery(surveyId))

  const { data: surveyResponse } = useQuery(getSurveyResponseQuery(surveyId))

  const { data: form = {} } = useQuery(getApplicationFormQuery(survey?.formId))
  const { data: community } = useQuery(getCommunityOverviewQuery(communityId))
  const { mutate: submitSurveyMutation } = useMutation({
    mutationFn: submitSurvey,
    onSuccess: () => {
      console.log("Survey submitted")
    },
  })

  return (
    <div>
      <div className="relative mb-32 max-h-96 w-full md:mb-20">
        <Image
          src={community?.mainImage || ""}
          alt={community?.name || "Community main image"}
          className="max-h-96 w-full"
        />
        <h2
          className={clsx(
            "absolute left-1/2 top-1/4 -translate-x-1/2 ",
            "w-full",
            "text-center text-2xl font-semibold text-white lg:text-heading2",
          )}
        >
          {survey?.title}
        </h2>

        <div
          className={clsx(
            "flex flex-col items-center justify-between gap-3 md:flex-row",
            "absolute left-1/2 -translate-x-1/2 -translate-y-1/4 md:-translate-y-1/2",
            "mx-auto w-auto p-4 md:w-2/3",
            "border border-pearl-lighter bg-white",
            "rounded-xl",
          )}
        >
          <Countdown deadline={survey?.deadline || new Date()} />
          <div className="flex flex-col items-center gap-1">
            <div className="flex justify-center gap-2">
              <Button variant="ternary" small onClick={() => {}}>
                <Trans>Save</Trans>
              </Button>
              <Button small onClick={() => formRef.current?.handleSubmit()}>
                <Trans>Submit</Trans>
              </Button>
            </div>
            {surveyResponse?.status === SurveyStatus.Draft && (
              <span className="text-center text-paragraph-medium font-normal text-blue">
                <Trans>Status: Draft</Trans>
              </span>
            )}
          </div>
        </div>
      </div>
      <div className="flex flex-col-reverse items-start justify-between gap-6 lg:flex-row">
        <div className="w-full rounded-xl border border-pearl-lighter bg-white p-6">
          <div className="flex flex-col gap-10 border-b border-pearl-lighter pb-7">
            <p className="font-light text-black">{survey?.description}</p>
          </div>
          <div className="py-6">
            <GenericFormViewer
              ref={formRef}
              formDefinition={form}
              defaultAnswers={{
                answers: surveyResponse?.answers || [],
              }}
              onSave={({ answers }) => {
                submitSurveyMutation({
                  id: surveyId || 0,
                  status: SurveyStatus.Submitted,
                  answers,
                })
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
