import { createFileRoute } from "@tanstack/react-router"

import { SurveyDefinitionPage } from "@src/components/pages/Surveys/SurveyDefinitionPage"

export const Route = createFileRoute(
  "/_auth/_community/community/$communityId/_admin-panel/admin-panel/surveys/$surveyId/",
)({
  component: () => <SurveyDefinitionPageWrapper />,
  validateSearch: (search: Record<string, unknown>) => {
    return {
      duplicate: search.duplicate === true ? true : false,
    }
  },
})

function SurveyDefinitionPageWrapper() {
  const { surveyId } = Route.useParams()
  const { duplicate } = Route.useSearch()

  const parsedSurveyId = parseInt(surveyId)

  return (
    <SurveyDefinitionPage surveyId={parsedSurveyId} duplicate={duplicate} />
  )
}
