import { Slider } from "@src/components/atoms/Slider"
import { Label, Field } from "@src/components/atoms/Fieldset"
import { AnswerProps } from "./types"

export const ScaleAnswer = ({ form, question }: AnswerProps) => {
  const questionOrder = question?.questionOrder ?? 0
  const questionOptions = question?.questionOptions || []
  const fromOption = questionOptions[0]
  const toOption = questionOptions[questionOptions.length - 1]

  const handleChange = (value: number) => {
    const currentAnswer = form.state.values.answers?.[questionOrder] ?? {}

    form.setFieldValue("answers", {
      ...form.state.values.answers,
      [questionOrder]: {
        ...currentAnswer,
        optionAnswerOrder: value,
        formQuestionId: question.id,
      },
    })
  }

  return (
    <Field className="flex-col">
      <Label
        htmlFor={question.title || ""}
        required={question.mandatoryAnswer}
        description={question.description || ""}
      >
        {question.title}
      </Label>

      <form.Field name={`answers[${questionOrder}].optionAnswerOrder`}>
        {(field) => (
          <Slider
            min={fromOption?.questionOptionOrder || 0}
            max={toOption?.questionOptionOrder || 0}
            value={field.state.value || 0}
            step={1}
            onChange={handleChange}
            fromLabel={fromOption?.questionOption || ""}
            toLabel={toOption?.questionOption || ""}
          />
        )}
      </form.Field>
    </Field>
  )
}
