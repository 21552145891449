import React from "react"
import {
  Popover as HeadlessPopover,
  PopoverButton,
  PopoverPanel,
} from "@headlessui/react"
import clsx from "clsx"

type ReusablePopoverProps = {
  button: React.ReactNode
  panelContent: React.ReactNode
  className?: string
}

export const Popover = ({
  button,
  panelContent,
  className = "",
}: ReusablePopoverProps) => {
  return (
    <HeadlessPopover className="relative inline-block">
      <PopoverButton className="focus:outline-none">{button}</PopoverButton>

      <PopoverPanel
        className={clsx(
          "absolute left-0 z-10 w-48 rounded-lg border border-pearl-lighter bg-white p-2 shadow-lg",
          className,
        )}
      >
        {panelContent}
      </PopoverPanel>
    </HeadlessPopover>
  )
}
