import {
  FormAnswerDto,
  FormQuestionDto,
  FormQuestionConditionalLogicDto,
  FormQuestionConditionLogic,
  FormQuestionConditionalLogicType,
} from "@src/api/form"

export const validateCondition = (
  answers: {
    [formQuestionId: number]: (FormAnswerDto & {
      questionOptionId?: number
    })[]
  },
  rule: FormQuestionConditionalLogicDto,
): boolean => {
  const answerPerQuestion = answers[rule?.conditionFormQuestionId ?? 0]
  const { conditionLogic, customValue, formQuestionOptionId } = rule

  const checkAnswer = (predicate: (answer: string | number) => boolean) =>
    answerPerQuestion?.some(
      ({ answer, optionAnswerOrder, questionOptionId }) => {
        if (answer !== undefined && answer !== null) {
          return predicate(answer)
        } else if (!isNaN(optionAnswerOrder || 0) && questionOptionId) {
          return predicate(`${optionAnswerOrder}`)
        }
        return false
      },
    )

  const checkOption = (predicate: (questionOptionId: number) => boolean) =>
    answerPerQuestion?.some(
      ({ questionOptionId }) =>
        questionOptionId !== undefined && predicate(questionOptionId),
    )

  switch (conditionLogic) {
    case FormQuestionConditionLogic.Equal:
      return customValue
        ? checkAnswer((answer) => answer === customValue)
        : checkOption((optionId) => optionId === Number(formQuestionOptionId))

    case FormQuestionConditionLogic.NotEqual:
      return customValue
        ? !checkAnswer((answer) => answer === customValue)
        : !checkOption((optionId) => optionId === Number(formQuestionOptionId))

    case FormQuestionConditionLogic.GreaterThan:
      return !isNaN(Number(customValue))
        ? checkAnswer((answer) => {
            if (!isNaN(Number(answer))) {
              return Number(customValue) < Number(answer)
            }
            return true
          })
        : false

    case FormQuestionConditionLogic.LessThan:
      return !isNaN(Number(customValue))
        ? checkAnswer((answer) => {
            if (!isNaN(Number(answer))) {
              return Number(customValue) > Number(answer)
            }
            return true
          })
        : false

    case FormQuestionConditionLogic.Contains:
      if (typeof customValue === "string") {
        return checkAnswer(
          (answer) =>
            typeof answer === "string" && answer.includes(customValue),
        )
      }
      return false

    case FormQuestionConditionLogic.DoesNotContain:
      if (typeof customValue === "string") {
        return checkAnswer(
          (answer) =>
            typeof answer === "string" && !answer.includes(customValue),
        )
      }
      return false

    case FormQuestionConditionLogic.IsEmpty:
      return (
        !answerPerQuestion?.length ||
        (customValue
          ? checkAnswer((answer) => !answer)
          : checkOption((optionId) => !optionId))
      )

    case FormQuestionConditionLogic.NotEmpty:
      return customValue
        ? checkAnswer((answer) => !!answer)
        : checkOption((optionId) => !!optionId)

    default:
      return true
  }
}

export const validateConditionalRules = (
  answers: {
    [formQuestionId: number]: FormAnswerDto[]
  },
  conditionalLogic: FormQuestionConditionalLogicDto[] | undefined,
  conditionalLogicType: FormQuestionConditionalLogicType | undefined,
): boolean => {
  if (!conditionalLogic?.length) return true

  if (
    conditionalLogicType === FormQuestionConditionalLogicType.AnyConditional
  ) {
    return conditionalLogic.some((rule) => validateCondition(answers, rule))
  }

  if (
    conditionalLogicType === FormQuestionConditionalLogicType.AllConditionals
  ) {
    return conditionalLogic.every((rule) => validateCondition(answers, rule))
  }

  return true
}

export const filterAnswersByConditional = (
  answers: FormAnswerDto[],
  questions: FormQuestionDto[],
  conditionalLogic: FormQuestionConditionalLogicDto[],
): {
  [formQuestionId: number]: FormAnswerDto[]
} => {
  const filteredAnswers = {} as {
    [formQuestionId: number]: FormAnswerDto[]
  }

  conditionalLogic?.forEach(({ conditionFormQuestionId = 0 }) => {
    const currentQuestion = questions.find(
      ({ id }) => conditionFormQuestionId === id,
    )
    const currentAnswers = answers.filter(
      ({ formQuestionId }) => formQuestionId === conditionFormQuestionId,
    )

    if (currentAnswers.length) {
      // const updatedAnswer = answers[conditionFormQuestionId].filter(
      //   (entry) => entry.formQuestionId === conditionFormQuestionId,
      // )

      filteredAnswers[conditionFormQuestionId] = currentAnswers.map(
        (answer) => {
          const questionRelatedToAnswer =
            currentQuestion?.questionOptions?.find(
              ({ questionOptionOrder }) =>
                questionOptionOrder === answer.optionAnswerOrder,
            )

          return {
            ...answer,
            questionOptionId: questionRelatedToAnswer?.id,
          }
        },
      )
    }
  })

  return filteredAnswers
}
