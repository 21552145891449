import { client } from "./client"
import { safeQueryOptions } from "@src/utils/query"
import { UnpackPromise, UnpackArray } from "@src/utils/types"

export type Country = {
  id: number
  name: string | null
  code: string | null
  timezone: string | null
}

export const countryKeys = {
  all: ["countries"],
  countryList: () => [...countryKeys.all, "list"],
}

export const getCountries = async () => {
  const data = await client.GET("/api/Country/GetAll", {
    params: {
      query: {
        loadGlobalOption: false,
      },
    },
  })

  return data
}

export type CommunityCircleDetail = UnpackArray<
  UnpackPromise<ReturnType<typeof getCountries>>
>

export const getCountriesQuery = () =>
  safeQueryOptions({
    queryFn: getCountries,
    queryKeys: countryKeys.countryList(),
    unsafeQueryFnArgs: [],
  })
