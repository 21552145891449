import { CurrencyInput } from "@src/components/atoms/CurrencyInput"
import { AnswerProps } from "./types"
import { useValidators } from "./validators"

export const MoneyAnswer = ({ form, question }: AnswerProps) => {
  const { requiredString } = useValidators()
  const questionOrder = question?.questionOrder ?? 0

  const handleChange = (value: string) => {
    const currentAnswer = form.state.values.answers?.[questionOrder] ?? {}

    form.setFieldValue("answers", {
      ...form.state.values.answers,
      [questionOrder]: {
        ...currentAnswer,
        answer: value,
        formQuestionId: question.id,
      },
    })
  }

  return (
    <form.Field
      name={`answers[${questionOrder}].answer`}
      validators={question.mandatoryAnswer ? requiredString : undefined}
    >
      {(field) => (
        <div className="flex items-end gap-1">
          <CurrencyInput
            name={`answers[${questionOrder}].answer`}
            error={field.state.meta.errors.join(", ")}
            placeholder={question.title || ""}
            label={question.title || ""}
            description={question.description || ""}
            required={question.mandatoryAnswer}
            type="number"
            onChange={handleChange}
            value={field.state.value || ""}
            onBlur={field.handleBlur}
          />
        </div>
      )}
    </form.Field>
  )
}
