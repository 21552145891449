import { useMemo, useState } from "react"
import { useQuery } from "@tanstack/react-query"
import { Trans, t } from "@lingui/macro"

import { getCommunityCirclesQuery } from "@src/api/circle"
import { getCommunityTagsQuery } from "@src/api/tags"
import { getCommunityUsersQuery } from "@src/api/community"
import { ApiDynamicFilterType } from "@src/api/filters"
import { Privacy } from "@src/api/privacy"

import { useCommunityId } from "@src/hooks/useCommunityId"
import { MultiSelect } from "@src/components/atoms/MultiSelect"
import { Select } from "@src/components/atoms/Select"
import { UserPicture } from "@src/components/molecules/UserPicture"

export type PrivacyUserSelectorProps = {
  circleIds: number[]
  tags: string[]
  onChange: ({
    circleIds,
    tags,
  }: {
    circleIds: number[]
    tags: string[]
  }) => void
}

export const PrivacyUserSelector = ({
  circleIds = [],
  tags = [],
  onChange,
}: PrivacyUserSelectorProps) => {
  const privacyOptions = [
    { id: Privacy.Public, label: t`Public` },
    { id: Privacy.Custom, label: t`Custom` },
    { id: Privacy.CommunityAdmin, label: t`Admin` },
  ]

  const [privacyType, setPrivacyType] = useState<{
    id: Privacy
    label: string
  }>(privacyOptions[0])

  const communityId = useCommunityId()

  const { data: communityCircles = [] } = useQuery(
    getCommunityCirclesQuery(communityId),
  )
  const { data: communityTags = [] } = useQuery(
    getCommunityTagsQuery(communityId),
  )
  const { data: communityUsers } = useQuery(
    getCommunityUsersQuery({
      communityId: communityId || 0,
      tags: tags,
      circleFilter: {
        filterType: ApiDynamicFilterType.IsAnyOf,
        values: circleIds,
      },
    }),
  )
  const selectedUsers =
    tags.length || circleIds.length ? communityUsers?.users?.list : []

  const circleIdsValue = useMemo(() => {
    const circleIdsSet = new Set(circleIds)

    return communityCircles
      .filter(({ id }) => id !== undefined && circleIdsSet.has(id))
      .map(({ id, name }) => ({
        id: id || 0,
        label: name || t`Unnamed Circle`,
      }))
  }, [communityCircles, circleIds])

  const tagsValue = useMemo(() => {
    if (!tags?.length || !communityTags?.length) return []
    const tagsSet = new Set(tags)

    return communityTags
      .filter((tag) => tag !== undefined && tagsSet.has(tag))
      .map((tag) => ({
        id: tag,
        label: tag,
      }))
  }, [communityTags, tags])

  return (
    <div className="grid grid-cols-1 gap-6 rounded-xl border border-pearl-lighter bg-white px-4 py-6 md:grid-cols-2">
      <Select
        value={privacyType}
        label={t`Privacy type`}
        placeholder={t`Select Privacy type`}
        className="col-span-2 md:col-span-1 md:row-start-1"
        options={privacyOptions}
        onSelect={(option) => setPrivacyType(option)}
      />

      {privacyType.id === Privacy.Custom && (
        <>
          <MultiSelect
            value={circleIdsValue}
            label={t`Programs`}
            placeholder={t`Select Programs`}
            className="col-span-2 md:col-span-1 md:row-start-2"
            options={communityCircles?.map(({ id, name }) => ({
              id: id || 0,
              label: name || "",
            }))}
            onSelect={(values) =>
              onChange({ circleIds: values.map((v) => v.id as number), tags })
            }
          />
          <MultiSelect
            value={tagsValue}
            label={t`Tags`}
            placeholder={t`Select Tags`}
            className="col-span-2 md:col-span-1 md:row-start-2"
            options={communityTags?.map((tag) => ({
              id: tag,
              label: tag,
            }))}
            onSelect={(values) =>
              onChange({ circleIds, tags: values.map((v) => v.label) })
            }
          />
          {selectedUsers?.length ? (
            <div className="col-span-2 text-paragraph-medium font-light text-gray md:row-start-3">
              <div className="flex items-center gap-1">
                {selectedUsers?.slice(0, 5).map((user) => (
                  <span className="-ml-2 h-6 first:ml-0" key={user.id}>
                    <UserPicture
                      className="vertical-align-middle"
                      key={user.id}
                      src={user.photo}
                      name={`${user.firstName} ${user.lastName}`}
                      link={`/user/${user.id}`}
                    />
                  </span>
                ))}
                <span className="font-normal text-blue">
                  {selectedUsers?.length ?? 0}
                </span>
                <Trans>selected users</Trans>
              </div>
            </div>
          ) : null}
        </>
      )}
    </div>
  )
}
