import { useState } from "react"
import {
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
} from "@headlessui/react"
import clsx from "clsx"

import { Field, Label, ErrorMessage } from "@src/components/atoms/Fieldset"
import { Icon } from "@src/components/atoms/Icon"
import { Checkbox } from "@src/components/atoms/Checkbox"

const styles = {
  base: [
    "relative flex items-center justify-between w-full rounded-xl border", // Base styles
    "text-paragraph-medium", // Typography
    "focus:outline-none appearance-none", // Hide default focus styles
    "data-[invalid]:border-red", // Invalid state
  ],
  primary: [
    "border-pearl-lighter focus:border-pearl-light bg-transparent text-black",
  ],
  secondary: [
    "bg-pearl-lighter/10 focus:border-pearl-lighter border-pearl-lighter/10 text-black-light",
  ],
  size: (small: boolean) =>
    small ? ["py-1 pl-3 pr-1 gap-2"] : ["py-2 pl-5 pr-2 gap-4"],
}

export type Option<T extends number | string> = {
  id: T
  label: string
  disabled?: boolean
  children?: Option<T>[]
}

export type MultiLevelMultiSelectProps<T extends number | string> = {
  variant?: "primary" | "secondary"
  options: Option<T>[]
  label?: string
  placeholder?: string
  value?: Option<T>[]
  error?: string
  className?: string
  small?: boolean
  onSelect: (selectedOptions: Option<T>[]) => void
  disabled?: boolean
}

export const MultiLevelMultiSelect = <T extends number | string>({
  variant = "primary",
  label,
  placeholder,
  error,
  value = [],
  small = false,
  options,
  className,
  onSelect,
  disabled,
}: MultiLevelMultiSelectProps<T>) => {
  const [hoveredParent, setHoveredParent] = useState<Option<T>>(options[0])

  const isSelected = (option: Option<T>) => {
    return value.some((selected) => selected.id === option.id)
  }

  const isParentSelected = (option: Option<T>) => {
    return (
      option.children?.every((child) => isSelected(child)) || isSelected(option)
    )
  }

  const toggleSelection = (option: Option<T>, isParent: boolean = false) => {
    if (isParent && option.children) {
      const newSelection = isSelected(option)
        ? value.filter(
            (selected) =>
              selected.id !== option.id &&
              !option.children?.some((child) => child.id === selected.id),
          )
        : [...value, option, ...(option.children || [])]

      onSelect(newSelection)
    } else {
      const newSelection = isSelected(option)
        ? value.filter((selected) => selected.id !== option.id)
        : [...value, option]

      onSelect(newSelection)
    }
  }

  return (
    <Field className={clsx("flex-col", className)}>
      <Label htmlFor={label}>{label}</Label>

      <Listbox
        value={value}
        onChange={(options: Option<T>[]) => onSelect(options)}
        multiple
        disabled={disabled}
        invalid={!!error}
      >
        <ListboxButton
          className={clsx(styles.base, styles[variant], styles.size(small))}
        >
          <span className="text-gray">{placeholder}</span>
          <Icon
            className="group pointer-events-none text-black"
            icon="keyboard_arrow_down"
            size="large"
          />
        </ListboxButton>

        <ListboxOptions
          anchor="bottom"
          transition
          className={clsx(
            "relative flex flex-wrap",
            "z-20 overflow-hidden",
            "my-2 w-[420px]",
            "border border-pearl-lighter bg-white",
            "font-semibold text-black",
          )}
        >
          <div className="grid w-full grid-cols-5 py-2">
            <div className="col-span-2 !h-96 overflow-auto border-r border-pearl-lighter">
              {options.map((option) => (
                <div key={option.id} className="group relative">
                  <ListboxOption
                    value={option}
                    onClick={() => toggleSelection(option, true)}
                    disabled={option.disabled}
                    onMouseEnter={() => setHoveredParent(option)}
                    className={clsx(
                      "flex items-center justify-between gap-2 overflow-auto",
                      "mx-2 cursor-pointer select-none rounded-md p-2 hover:bg-blue-light/50",
                      "break-all text-paragraph-medium font-light text-black",
                      {
                        "bg-blue-light/50": option.id === hoveredParent?.id,
                      },
                    )}
                  >
                    <span className="flex items-center gap-2">
                      <Checkbox
                        checked={isParentSelected(option)}
                        onChange={() => toggleSelection(option, true)}
                      />
                      {option.label}
                    </span>

                    <Icon icon="keyboard_arrow_right" />
                  </ListboxOption>
                </div>
              ))}
            </div>
            <div className="col-span-3 !h-96 overflow-auto">
              {hoveredParent?.children?.map((childOption) => (
                <ListboxOption
                  key={childOption.id}
                  value={childOption}
                  onClick={() => toggleSelection(childOption)}
                  disabled={childOption.disabled}
                  className={clsx(
                    "flex items-center gap-2 overflow-auto",
                    "mx-2 cursor-pointer select-none rounded-md p-2 hover:bg-blue-light/50",
                    "break-all text-paragraph-medium font-light text-black",
                  )}
                >
                  <Checkbox
                    checked={isSelected(childOption)}
                    onChange={() => toggleSelection(childOption)}
                  />
                  {childOption.label}
                </ListboxOption>
              ))}
            </div>
          </div>
        </ListboxOptions>
      </Listbox>
      <ErrorMessage>{error}</ErrorMessage>
    </Field>
  )
}
