import { createFileRoute } from "@tanstack/react-router"

import { FormPage } from "@src/components/pages/Forms/FormPage"

export const Route = createFileRoute(
  "/_auth/_community/community/$communityId/_admin-panel/admin-panel/forms/$formId",
)({
  component: () => <FormPageWrapper />,
  validateSearch: (search: Record<string, unknown>) => {
    return {
      view_mode: search.view_mode === true ? true : false,
      duplicate: search.duplicate === true ? true : false,
    }
  },
})

function FormPageWrapper() {
  const { formId } = Route.useParams()
  const { view_mode, duplicate } = Route.useSearch()

  // TODO: handle failing to parse formId
  const parsedFormId = parseInt(formId)

  return (
    <FormPage
      formId={parsedFormId}
      viewMode={view_mode}
      duplicate={duplicate}
    />
  )
}
