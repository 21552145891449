import {
  saveCommunityInfo,
  saveCommunityAbout,
  CommunityEditInfo,
  CommunityOverview,
} from "@src/api/community"
import { uploadFile } from "@src/api/file"
import { GeneralSettingsFormValues } from "./"

export const updateCommunityMutationFn = async ({
  data,
  formValues,
}: {
  data: CommunityOverview
  formValues: GeneralSettingsFormValues
}) => {
  const newCommunityInfo: CommunityEditInfo = {
    id: data.id,
    name: formValues.name,
    shortDescription: formValues.shortDescription,
    mainImage: data.mainImage,
    logoImage: data.logoImage,
    description: formValues.description,
    countryId: data.countryId,
    city: data.city,
    menuLogoImage: data.menuLogoImage,
    communityTags: data.communityTags,
    hideParagraphsUntilDate: data.hideParagraphsUntilDate,
    generalSettingsHasLockTags: data.generalSettingsHasLockTags,
    defaultProjectFilterTag: data.defaultProjectFilterTag,
    allowTasks: data.allowTasks,
    hasLandingPage: data.hasLandingPage,
  }

  if (formValues.mainImage.blob) {
    const url = await uploadFile({
      file: formValues.mainImage.blob,
      folder: "community_image",
    })

    newCommunityInfo.mainImage = `/${url}`
  }

  if (formValues.logoImage.blob) {
    const url = await uploadFile({
      file: formValues.logoImage.blob,
    })

    newCommunityInfo.logoImage = `/${url}`
  }

  await saveCommunityInfo(newCommunityInfo)
  await saveCommunityAbout({
    id: data.id,
    description: formValues.description,
  })
}
