import { Outlet } from "@tanstack/react-router"

export const UnauthLayout = () => {
  return (
    <div className="min-h-screen p-6">
      <h1 className="text-center">Babele</h1>
      <Outlet />
    </div>
  )
}
