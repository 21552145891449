import { t } from "@lingui/macro"
import { useLocation, Link } from "@tanstack/react-router"
import { useQuery } from "@tanstack/react-query"

import { getCommunityOverviewQuery } from "@src/api/community"
import { Icon } from "@src/components/atoms/Icon"
import { useCommunityId } from "@src/hooks/useCommunityId"

type CommunityBreadcrumbsProps = {
  isAdminPanel: boolean
}

export const CommunityBreadcrumbs = ({
  isAdminPanel,
}: CommunityBreadcrumbsProps) => {
  const translatedItems = {
    // admin
    "circle-management": t`Permissions`,
    "community-settings": t`Community Settings`,
    forms: t`Forms`,

    // community
    forum: t`Forum`,
    surveys: t`Surveys`,
    "application-definitions": t`Applications`,
  }

  const communityId = useCommunityId()

  const location = useLocation()
  const pathParts = location.pathname.split("/")
  const lastPart = pathParts[isAdminPanel ? 4 : 3]

  const { data: community } = useQuery(getCommunityOverviewQuery(communityId))

  const breadcrumb = [
    {
      text: community?.name,
      link: `/community/${community?.id}`,
      shouldShrink: true,
    },
    ...(isAdminPanel
      ? [
          {
            text: t`Admin Panel`,
            link: `/community/${community?.id}/admin-panel/community-settings`,
          },
        ]
      : []),
    { text: translatedItems[lastPart as keyof typeof translatedItems] },
  ]

  return (
    <div className="flex pb-4 lg:pb-6">
      {breadcrumb.map(({ text, link }, index) => (
        <span key={index} className="flex items-center">
          <Link
            to={link}
            className="text-paragraph-small font-semibold uppercase text-pearl-light sm:text-paragraph-medium sm:font-normal"
          >
            {text}
          </Link>
          {index < breadcrumb.length - 1 && (
            <Icon
              icon="keyboard_arrow_right"
              className="text-pearl-light"
              size="large"
            />
          )}
        </span>
      ))}
    </div>
  )
}
