import { useState } from "react"

import {
  Combobox,
  ComboboxInput,
  ComboboxButton,
  ComboboxOption,
  ComboboxOptions,
} from "@headlessui/react"
import clsx from "clsx"

import { Field, Label, ErrorMessage } from "@src/components/atoms/Fieldset"
import { Icon } from "@src/components/atoms/Icon"
import { Checkbox } from "@src/components/atoms/Checkbox"
import { ApiDynamicFilterType } from "@src/api/filters"
import { RadioButtonList } from "@src/components/atoms/RadioButtonList"
import { t } from "@lingui/macro"

const styles = {
  base: [
    "relative flex items-center justify-between w-full rounded-xl border bg-white", // Base styles
    "text-paragraph-medium", // Typography
    "focus-within:border-pearl-light", // Focus if input or button
    "focus:outline-none appearance-none", // Hide default focus styles
    "placeholder:text-gray",
  ],
  primary: [
    "border-pearl-lighter focus:border-pearl-light bg-transparent text-black",
  ],
  secondary: [
    "bg-pearl-lighter/10 focus:border-pearl-lighter border-pearl-lighter/10 text-black-light",
  ],
  size: (small: boolean) =>
    small ? ["py-1 pl-3 pr-1 gap-2"] : ["py-2 pl-5 pr-2 gap-4"],
}

export type Option<T extends number | string> = {
  id: T
  label: string
  disabled?: boolean
}

export type AdvancedFilterOption = {
  id: ApiDynamicFilterType
  label: string
}

export type MultiComboboxProps<T extends number | string> = {
  variant?: "primary" | "secondary"
  options: Option<T>[]
  label?: string
  placeholder?: string
  value?: Option<T>[]
  error?: string
  className?: string
  small?: boolean
  onSelect: (selectedOptions: Option<T>[]) => void
  disabled?: boolean
  advancedFilterValue?: ApiDynamicFilterType
  onFilterChange?: (filterOption: AdvancedFilterOption) => void
}

const AdvancedFilterOptions = () => [
  { id: ApiDynamicFilterType.IsAnyOf, label: t`Is any of` },
  { id: ApiDynamicFilterType.IsNoneOf, label: t`Is none of` },
]

export const MultiCombobox = <T extends number | string>({
  variant = "primary",
  label,
  placeholder,
  error,
  value = [],
  small = false,
  options,
  className,
  onSelect,
  disabled,
  advancedFilterValue,
  onFilterChange,
}: MultiComboboxProps<T>) => {
  const [query, setQuery] = useState("")
  const filteredOptions =
    query === ""
      ? options
      : options.filter((option) =>
          option.label.toLowerCase().includes(query.toLowerCase()),
        )

  const isSelected = (option: Option<T>) =>
    value.some((selected) => selected.id === option.id)

  const toggleSelection = (option: Option<T>) => {
    const newSelection = isSelected(option)
      ? value.filter((selected) => selected.id !== option.id)
      : [...value, option]

    onSelect(newSelection)
  }

  const hasAdvancedFilter =
    advancedFilterValue !== undefined && onFilterChange !== undefined

  return (
    <Field className={clsx("flex-col", className)}>
      <Label htmlFor={label}>{label}</Label>

      <Combobox
        value={value}
        virtual={{ options: filteredOptions }}
        multiple
        disabled={disabled}
        onClose={() => setQuery("")}
      >
        <div className="relative">
          <ComboboxInput
            className={clsx(styles.base, styles[variant], styles.size(small))}
            placeholder={placeholder}
            onChange={(event) => setQuery(event.target.value)}
          />
          <ComboboxButton className="group absolute inset-y-0 right-0 flex items-center px-2.5">
            <Icon
              className="group pointer-events-none text-black"
              icon="keyboard_arrow_down"
              size="large"
            />
          </ComboboxButton>
        </div>
        <ComboboxOptions
          anchor="bottom"
          transition
          className={clsx(
            "z-20 grid !max-h-60 flex-1 gap-0.5 overflow-y-auto py-2",
            "my-2 w-[var(--input-width)]",
            "border border-pearl-lighter bg-white",
            "font-semibold text-black",
          )}
        >
          {({ option }) => (
            <>
              {hasAdvancedFilter && (
                <RadioButtonList
                  options={AdvancedFilterOptions()}
                  value={advancedFilterValue}
                  onChange={onFilterChange}
                  className="border-b border-pearl-lighter px-4 pb-3 pt-2"
                />
              )}
              <ComboboxOption
                key={option.id}
                value={option}
                onClick={() => toggleSelection(option)}
                disabled={option.disabled}
                className={clsx(
                  "group flex items-center gap-2 overflow-auto",
                  "mx-2 cursor-pointer select-none rounded-md p-2 hover:bg-blue-light/50",
                  "break-all text-paragraph-medium font-light text-black",
                )}
              >
                <Checkbox
                  checked={isSelected(option)}
                  onChange={() => toggleSelection(option)}
                />
                {option.label}
              </ComboboxOption>
            </>
          )}
        </ComboboxOptions>
      </Combobox>
      <ErrorMessage>{error}</ErrorMessage>
    </Field>
  )
}
