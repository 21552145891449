import { ReactNode } from "react"

export const commonStyles = {
  base: [
    "relative flex items-center justify-between w-full rounded-xl border bg-white", // Base styles
    "text-paragraph-medium", // Typography
    "focus:outline-none appearance-none", // Hide default focus styles
    "data-[invalid]:border-red", // Invalid state
  ],
  primary: [
    "border-pearl-lighter focus:border-pearl-light bg-transparent text-black",
  ],
  secondary: [
    "bg-pearl-lighter/10 focus:border-pearl-lighter border-pearl-lighter/10 text-black-light",
  ],
  ternary: [
    "bg-ghost-white border-transparent text-black placeholder:text-gray-dark",
  ],
  options: [
    "z-20 grid flex-1 gap-0.5",
    "my-2 w-[var(--button-width)] p-2 max-h-60",
    "border border-pearl-lighter bg-white",
    "font-semibold text-black",
    "overflow-y-auto",
  ],
  option: [
    "group flex items-center gap-2 overflow-auto",
    "cursor-pointer select-none rounded-md px-4 py-2 hover:bg-blue-light/50",
    "text-paragraph-medium font-light text-black",
  ],
}

export type FieldProps = {
  variant?: "primary" | "secondary" | "ternary"
  className?: string
  id?: string
  label?: string
  info?: ReactNode
  insideLabel?: string
  small?: boolean
  error?: string
  placeholder?: string
  description?: string
  required?: boolean
  onBlur?: () => void
  disabled?: boolean
}

export type OptionProps<T extends number | string> = {
  id: T
  label: string
  group?: string
  icon?: string
  disabled?: boolean
}
