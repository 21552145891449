import { http, HttpResponse } from "msw"

import { AssertionFunction } from "./common"
import { SurveyDefinition } from "./survey"

export const getSurveyDefinition_SuccessResponse = (id: number) => {
  const response: SurveyDefinition = {
    id,
    title: "Startup Feedback Survey",
    description:
      "We have selected 12 startups who are currently enrolled in our programme. The programme started in July and will continue until this year’s Demo Day which will happen in November 2024. Over these four months, these startups will undergo an intensive program, including local and global sessions, coaching, and pitching practice sessions, all aimed at refining their innovative solutions. Each startup will have a dedicated coach and, by the end of the program, will receive a €10,000 grant to further their impact. At the end of the program the startups will participate in a demo day, where the top 3 startups will be awarded with €20,000, €10,000 and €5,000 respectively.",
    surveyType: 1,
    communityId: 1,
    formId: 1515,
    recurrence: 0,
    KPIConvert: true,
    startDate: new Date("2024-01-01T09:00:00Z"),
    startDateTimezoneCode: "Europe/Bucharest",
    endDate: new Date("2024-01-31T18:00:00Z"),
    endDateTimezoneCode: "Europe/Bucharest",
    circleList: [11, 22, 33, 1886],
    tags: ["feedback", "satisfaction", "New2025"],
    methodologyList: [101, 202],
    deadline: new Date("2024-03-01T09:00:00Z"),
  }

  return response
}

export const getSurveyDefinitionList_SuccessResponse = (
  communityId: number,
) => {
  const response: SurveyDefinition[] = [
    {
      id: 1,
      title: "Survey 1",
      communityId: communityId,
      description:
        "We have selected 12 startups who are currently enrolled in our programme. The programme started in July and will continue until this year’s Demo Day which will happen in November 2024. Over these four months, these startups will undergo an intensive program, including local and global sessions, coaching, and pitching practice sessions, all aimed at refining their innovative solutions. Each startup will have a dedicated coach and, by the end of the program, will receive a €10,000 grant to further their impact. At the end of the program the startups will participate in a demo day, where the top 3 startups will be awarded with €20,000, €10,000 and €5,000 respectively.",
      surveyType: 1,
      formId: 1515,
      recurrence: 0,
      KPIConvert: true,
      startDate: new Date("2024-01-01T09:00:00Z"),
      startDateTimezoneCode: "Europe/Bucharest",
      endDate: new Date("2024-01-31T18:00:00Z"),
      endDateTimezoneCode: "Europe/Bucharest",
      circleList: [11, 22, 33, 1886],
      tags: ["feedback", "satisfaction", "New2025"],
      methodologyList: [101, 202],
      deadline: new Date("2024-03-01T09:00:00Z"),
    },
    {
      id: 2,
      title: "Survey 2",
      description:
        "We have selected 12 startups who are currently enrolled in our programme. The programme started in July and will continue until this year’s Demo Day which will happen in November 2024. Over these four months, these startups will undergo an intensive program, including local and global sessions, coaching, and pitching practice sessions, all aimed at refining their innovative solutions. Each startup will have a dedicated coach and, by the end of the program, will receive a €10,000 grant to further their impact. At the end of the program the startups will participate in a demo day, where the top 3 startups will be awarded with €20,000, €10,000 and €5,000 respectively.",
      surveyType: 1,
      communityId: communityId,
      formId: 1515,
      recurrence: 0,
      KPIConvert: true,
      startDate: new Date("2024-01-01T09:00:00Z"),
      startDateTimezoneCode: "Europe/Bucharest",
      endDate: new Date("2024-01-31T18:00:00Z"),
      endDateTimezoneCode: "Europe/Bucharest",
      circleList: [11, 22, 33, 1886],
      tags: ["feedback", "satisfaction", "New2025"],
      methodologyList: [101, 202],
      deadline: new Date("2024-03-01T09:00:00Z"),
    },
    {
      id: 3,
      title: "Survey 3",
      description:
        "We have selected 12 startups who are currently enrolled in our programme. The programme started in July and will continue until this year’s Demo Day which will happen in November 2024. Over these four months, these startups will undergo an intensive program, including local and global sessions, coaching, and pitching practice sessions, all aimed at refining their innovative solutions. Each startup will have a dedicated coach and, by the end of the program, will receive a €10,000 grant to further their impact. At the end of the program the startups will participate in a demo day, where the top 3 startups will be awarded with €20,000, €10,000 and €5,000 respectively.",
      surveyType: 1,
      communityId: communityId,
      formId: 1515,
      recurrence: 0,
      KPIConvert: true,
      startDate: new Date("2024-01-01T09:00:00Z"),
      startDateTimezoneCode: "Europe/Bucharest",
      endDate: new Date("2024-01-31T18:00:00Z"),
      endDateTimezoneCode: "Europe/Bucharest",
      circleList: [11, 22, 33, 1886],
      tags: ["feedback", "satisfaction", "New2025"],
      methodologyList: [101, 202],
      deadline: new Date("2024-03-01T09:00:00Z"),
    },
  ]

  return response
}

export const getSurveyDefinition_SuccessHandler = (
  assertions?: AssertionFunction,
) =>
  http.get(
    `${import.meta.env.VITE_API_BASE_URL}/api/survey-definition/:id`,
    ({ params, request }) => {
      if (assertions) {
        assertions({ params, request })
      }

      return HttpResponse.json(getSurveyDefinition_SuccessResponse(1))
    },
  )

export const getSurveyDefinitionList_SuccessHandler = (
  assertions?: AssertionFunction,
) =>
  http.get(
    `${import.meta.env.VITE_API_BASE_URL}/api/survey-definition`,
    ({ params, request }) => {
      if (assertions) {
        assertions({ params, request })
      }

      return HttpResponse.json(getSurveyDefinitionList_SuccessResponse)
    },
  )

export const getSurveyResponse_SuccessResponse = (surveyId: number) => {
  const response = {
    id: surveyId,
    answers: [
      {
        id: 1,
        formId: 1515,
        formQuestionId: 101,
        answer: "Yes",
        optionAnswerOrder: 1,
        optionRowAnswerOrder: 1,
      },
      {
        id: 2,
        formId: 1515,
        formQuestionId: 102,
        answer: "No",
        optionAnswerOrder: 2,
        optionRowAnswerOrder: 1,
      },
    ],
    username: "John Doe",
    status: 0,
  }

  return response
}

export const getSurveyResponse_SuccessHandler = (
  assertions?: AssertionFunction,
) =>
  http.get(
    `${import.meta.env.VITE_API_BASE_URL}/api/survey-response/:surveyId`,
    ({ params, request }) => {
      if (assertions) {
        assertions({ params, request })
      }

      return HttpResponse.json(
        getSurveyResponse_SuccessResponse(Number(params.surveyId)),
      )
    },
  )
