import { FormDto, FormQuestionDto, FormQuestionOptionDto } from "@src/api/form"
import {
  EditorFormDto,
  FormQuestionConditionalLogicWithOrder,
} from "@src/api/form"

export const prepareFormSaveStep1 = (form: EditorFormDto) => {
  const initialForm = { ...form }

  // Fix for existing data where the last two question options have the same questionOptionOrder
  initialForm?.questions?.forEach((question) => {
    const questionOptions = question.questionOptions
    if (questionOptions?.length && questionOptions?.length > 1) {
      const lastOption = questionOptions[questionOptions.length - 1]
      const secondLastOption = questionOptions[questionOptions.length - 2]

      if (
        lastOption.questionOptionOrder === secondLastOption.questionOptionOrder
      ) {
        // @ts-expect-error this is not undefined
        lastOption.questionOptionOrder++
      }
    }
  })

  // Prepare form for the first API step by removing conditional logic and setting new IDs
  return {
    ...initialForm,
    questions: initialForm?.questions?.map(({ id, ...rest }) => {
      const question = {
        ...rest,
        formQuestionConditionalLogicList: [],
        id: id || 0,
        questionOptions: rest.questionOptions?.map((option) => ({
          ...option,
          id: option.id || 0,
        })),
      }

      delete question.temporaryQuestionId
      return question
    }),
  }
}

export const prepareFormSaveStep2 = (
  initialForm: EditorFormDto,
  formStep2: FormDto,
) => {
  const oldIdToNewIdMap: { [key: number]: number } = {}
  const oldOptionIdToNewOptionIdMap: { [key: number]: number } = {}

  // Map old question IDs to new question IDs based on questionOrder
  initialForm?.questions?.forEach((initialQuestion) => {
    const newQuestion = formStep2.questions?.find(
      (questionStep2: FormQuestionDto) =>
        questionStep2.questionOrder === initialQuestion.questionOrder,
    )
    if (newQuestion && initialQuestion?.temporaryQuestionId) {
      oldIdToNewIdMap[initialQuestion?.temporaryQuestionId] =
        newQuestion.id || 0

      // Map old question option IDs to new option IDs based on questionOptionOrder
      initialQuestion.questionOptions?.forEach((oldOption) => {
        const newOption = newQuestion.questionOptions?.find(
          (option: FormQuestionOptionDto) =>
            option.questionOptionOrder === oldOption.questionOptionOrder,
        )
        if (newOption && oldOption?.id) {
          oldOptionIdToNewOptionIdMap[oldOption.id] = newOption.id || 0
        }
      })
    }
  })

  // Step 3: Re-add conditional logic with updated question and option IDs
  const formWithConditionalLogic = {
    ...formStep2,
    questions: formStep2?.questions?.map((questionStep2: FormQuestionDto) => {
      const originalQuestion = initialForm?.questions?.find(
        (q) => q.questionOrder === questionStep2.questionOrder,
      )

      const updatedLogic =
        originalQuestion?.formQuestionConditionalLogicList?.map(
          (logic: FormQuestionConditionalLogicWithOrder) => ({
            ...logic,
            conditionFormQuestionId:
              oldIdToNewIdMap[logic?.temporaryQuestionId ?? 0],
            formQuestionOptionId: logic.formQuestionOptionId
              ? oldOptionIdToNewOptionIdMap[logic.formQuestionOptionId]
              : null,
          }),
        )

      return {
        ...questionStep2,
        formQuestionConditionalLogicList: updatedLogic,
      }
    }),
  }

  return formWithConditionalLogic
}
