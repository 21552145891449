import { t } from "@lingui/macro"
import { z } from "zod"

export const useValidators = () => ({
  name: {
    onBlur: z
      .string()
      .min(3, t`The form name must be at least 3 characters`)
      .max(80, t`The form name must be under 80 characters`),
  },
  requiredString: {
    onBlur: z
      .string({
        required_error: t`This field is required`,
      })
      .refine((value) => value.length >= 0),
  },
  requiredNumber: {
    onBlur: z.number({
      required_error: t`This field is required`,
    }),
  },
  requiredDate: {
    onBlur: z.date({
      required_error: t`This field is required`,
    }),
  },
  requiredBoolean: {
    onBlur: z.boolean({
      required_error: t`This field is required`,
    }),
  },
})
