import { Input as HeadlessInput } from "@headlessui/react"
import clsx from "clsx"
import { useState, useRef } from "react"
import { DayPicker } from "react-day-picker"
import "react-day-picker/style.css"
import { format } from "date-fns"
import { commonStyles, FieldProps } from "@src/components/atoms/Fieldset/common"

import {
  Label,
  Field,
  ErrorMessage,
  LeftIcon,
  RightIcon,
} from "@src/components/atoms/Fieldset"
import { IconType } from "@src/config/icons"
import { useClickOutside } from "@src/hooks/useClickOutside"

export type DatePickerProps = FieldProps & {
  iconLeft?: IconType
  iconRight?: IconType
  name: string
  value?: Date | null
  onChange: (value: Date | null) => void
  hasTimePicker?: boolean
}

export const DatePicker = ({
  variant = "primary",
  small = false,
  label,
  error,
  iconLeft,
  iconRight,
  className,
  description,
  required,
  name,
  value,
  onChange,
  hasTimePicker = false,
  ...props
}: DatePickerProps) => {
  const [isDayPickerVisible, setDayPickerVisible] = useState(false)
  const ref = useRef<HTMLDivElement>(null)

  useClickOutside(ref, () => setDayPickerVisible(false))

  const selectedDate = value || new Date()
  const inputDate = hasTimePicker
    ? format(selectedDate, "dd/MM/yyyy HH:mm")
    : format(selectedDate, "dd/MM/yyyy")

  return (
    <Field className={clsx("flex-col", className)}>
      <Label htmlFor={name} required={required} description={description}>
        {label}
      </Label>

      <div className="relative">
        <HeadlessInput
          className={clsx(
            commonStyles.base,
            commonStyles[variant],
            small ? ["px-3 py-1"] : ["px-5 py-2"],
            iconLeft && "pl-10",
            iconRight && "pr-10",
          )}
          invalid={!!error}
          name={name}
          value={inputDate}
          onClick={() => setDayPickerVisible(!isDayPickerVisible)}
          readOnly
          {...props}
        />
        <LeftIcon icon={iconLeft} />
        <RightIcon icon={iconRight} />

        {isDayPickerVisible && (
          <div
            className="absolute z-10 mt-2 rounded-xl bg-white p-2 shadow-2xl"
            ref={ref}
          >
            {hasTimePicker && (
              <HeadlessInput
                type="time"
                className={clsx(
                  commonStyles.base,
                  commonStyles.primary,
                  "px-3 py-1",
                )}
                value={selectedDate ? format(selectedDate, "HH:mm") : ""}
                onChange={(e) => {
                  const [hours, minutes] = e.target.value.split(":").map(Number)
                  if (selectedDate) {
                    const newDate = new Date(selectedDate)
                    newDate.setHours(hours)
                    newDate.setMinutes(minutes)

                    onChange(newDate)
                  }
                }}
              />
            )}

            <DayPicker
              mode="single"
              selected={selectedDate}
              onSelect={(date) => {
                if (date) {
                  const newDate = new Date(date)

                  if (selectedDate) {
                    newDate.setHours(selectedDate.getHours())
                    newDate.setMinutes(selectedDate.getMinutes())
                  }

                  onChange(newDate)
                }
              }}
              classNames={{
                root: "p-4 w-80",
                month_grid: "border-separate",
                month_caption: "mb-4 font-medium text-paragraph",
                day: "rounded-full text-black-light text-center size-10 focus:outline-none",
                selected: "border border-blue text-blue",
                day_button: "text-center",
                weekday: "text-center font-medium text-black",
                today: "text-blue",
              }}
            />
          </div>
        )}
      </div>
      <ErrorMessage>{error}</ErrorMessage>
    </Field>
  )
}
