import { Root, Track, Range, Thumb } from "@radix-ui/react-slider"

export type SliderProps = {
  fromLabel: string
  toLabel: string
  value: number
  min: number
  max: number
  step?: number
  onChange: (value: number) => void
}

export const Slider = ({
  fromLabel,
  toLabel,
  value,
  min = 1,
  max = 10,
  step = 1,
  onChange,
}: SliderProps) => {
  return (
    <div className="mb-4 w-full max-w-lg py-2">
      <div className="mb-2 flex justify-between text-sm">
        <label className="font-semibold">{fromLabel}</label>
        <label className="font-semibold">{toLabel}</label>
      </div>

      <Root
        className="relative flex h-5 w-full touch-none items-center"
        min={min}
        max={max}
        step={step}
        defaultValue={[value]}
        onValueChange={(val) => onChange(val[0])}
      >
        <Track className="relative h-2 w-full rounded-full bg-pearl-lighter">
          <Range className="absolute h-full rounded-full bg-blue-lighter"></Range>
        </Track>

        <Thumb
          key={value}
          className="relative block size-5 rounded-full border border-blue bg-white"
        >
          <span className="absolute -bottom-6 left-0 rounded-md border border-pearl-lighter bg-white px-1 leading-none text-black" />
          <span className="absolute -bottom-6 left-0 rounded-md border border-pearl-lighter bg-white px-1 leading-none text-black">
            {value}
          </span>
        </Thumb>
      </Root>
    </div>
  )
}
