import { createContext, useState, ReactNode, useRef } from "react"

import { Dialog } from "@src/components/atoms/Dialog"

interface DialogData {
  title: string
  children?: ReactNode
  actions?: ReactNode
  onClose?: () => void
}

export interface DialogQueueContextValue {
  enqueueDialog: (dialogData: DialogData) => void
  closeCurrentDialog: () => void
}

export interface DialogQueueProviderProps {
  children: ReactNode
}

export const DialogQueueContext = createContext<
  DialogQueueContextValue | undefined
>(undefined)

export const DialogQueueProvider = ({ children }: DialogQueueProviderProps) => {
  const [currentDialog, setCurrentDialog] = useState<DialogData | null>(null)
  const dialogQueue = useRef<DialogData[]>([])

  const enqueueDialog = (dialogData: DialogData) => {
    dialogQueue.current = [...dialogQueue.current, dialogData]

    if (!currentDialog) {
      showNextDialog()
    }
  }

  const showNextDialog = () => {
    if (dialogQueue.current.length > 0) {
      const nextDialog = dialogQueue.current.shift()!

      setCurrentDialog(nextDialog)
    }
  }

  const closeCurrentDialog = () => {
    if (currentDialog?.onClose) currentDialog.onClose()
    setCurrentDialog(null)
    showNextDialog()
  }

  return (
    <DialogQueueContext.Provider value={{ enqueueDialog, closeCurrentDialog }}>
      {children}
      {currentDialog && (
        <Dialog
          open={true}
          title={currentDialog.title}
          children={currentDialog.children}
          actions={currentDialog.actions}
          onClose={closeCurrentDialog}
        />
      )}
    </DialogQueueContext.Provider>
  )
}
