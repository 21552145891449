import { useRef, useState } from "react"
import { Input as HeadlessInput } from "@headlessui/react"
import clsx from "clsx"

import { Button } from "@src/components/atoms/Button"
import { IconButton } from "../IconButton"
import { IconType } from "@src/config/icons"
import { Label, Field, ErrorMessage } from "@src/components/atoms/Fieldset"

// A newly uploaded file will have a `file` property with the file blob
export type UploadedFile = {
  fileName: string
  filePath?: string
  file?: File
  existing: boolean
}

export type FileInputProps = {
  className?: string
  variant?: "primary" | "secondary" | "ternary" | "text" | "translucent"
  icon?: IconType
  multiple?: boolean
  text?: string
  label?: string
  description?: string
  showFileNames?: boolean
  showRemoveFile?: boolean
  showRemoveAllFiles?: boolean
  onChange?: (files: UploadedFile[]) => void
  required?: boolean
  error?: string
  small?: boolean
  /* Initial value of the input field */
  value?: UploadedFile[]
  /* Name of the input field */
  name: string
  /* File types to accept, e.g. "image/*" */
  accept?: string
}

export const FileInput = ({
  className,
  variant,
  multiple = false,
  icon = "upload_file",
  label,
  description,
  onChange,
  showFileNames = true,
  showRemoveFile = true,
  showRemoveAllFiles = false,
  small = false,
  required = false,
  error,
  text,
  name,
  value,
  accept = "*", // Default to accept all file types
}: FileInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [files, setFiles] = useState<UploadedFile[]>(value || [])

  const handleFileChange = () => {
    const addedFiles = inputRef.current?.files

    if (addedFiles) {
      const newFiles = Array.from(addedFiles).map((file) => ({
        fileName: file.name,
        file,
        existing: false,
      }))
      const allFiles = multiple ? [...files, ...newFiles] : newFiles
      setFiles(allFiles)
      onChange?.(allFiles)
    }
  }

  const handleRemoveFile = (index: number) => {
    if (inputRef.current) {
      const dataTransfer = new DataTransfer()
      const clonedFiles = [...files]
      const newFiles = [
        ...clonedFiles.slice(0, index),
        ...clonedFiles.slice(index + 1),
      ]

      const newUploadedFiles = files.filter(
        (file) => !file.existing && file.file,
      )
      newUploadedFiles.forEach((file) =>
        dataTransfer.items.add(file.file as File),
      )
      inputRef.current.files = dataTransfer.files

      setFiles(newFiles)
      onChange?.(newFiles)
    }
  }

  const handleRemoveAllFiles = () => {
    if (inputRef.current) {
      inputRef.current.value = ""
      setFiles([])
      onChange?.([])
    }
  }

  const uploadButton = text ? (
    <Button
      variant={variant}
      icon={icon}
      onClick={() => inputRef.current?.click()}
      small={small}
    >
      {text}
    </Button>
  ) : (
    <IconButton
      icon="upload_file"
      size={small ? "small" : "default"}
      className={clsx(small ? "p-[6px]" : "p-2")}
      onClick={() => inputRef.current?.click()}
      variant={variant}
    />
  )

  return (
    <div className={clsx("relative", className)}>
      <Field>
        <Label htmlFor={name} required={required} description={description}>
          {label}
        </Label>

        <HeadlessInput
          ref={inputRef}
          type="file"
          className="hidden"
          onChange={handleFileChange}
          name={name}
          id={name}
          {...(multiple ? { multiple: true } : {})}
          data-testid="file-input"
          accept={accept}
        />
        <ErrorMessage>{error}</ErrorMessage>
      </Field>
      <div
        data-testid="button-container"
        className={clsx("flex gap-2", description || label ? "mt-2" : "")}
      >
        {uploadButton}

        {showRemoveAllFiles && files.length > 0 && (
          <IconButton
            icon="close"
            className={clsx(small ? "px-[6px]" : "px-2")}
            onClick={handleRemoveAllFiles}
            variant={variant}
            aria-label="Remove all files"
            size={small ? "small" : "default"}
          />
        )}
      </div>
      {files.length > 0 && showFileNames && (
        <div className="mt-2">
          {files.map((file, index) => (
            <div key={index} className="mt-2 flex items-center">
              {showRemoveFile && (
                <IconButton
                  variant="secondary"
                  onClick={() => handleRemoveFile(index)}
                  icon="close"
                  aria-label="Remove file"
                  className="mr-2 p-1"
                  size={small ? "small" : "default"}
                />
              )}
              <span
                className={clsx(
                  small ? "text-paragraph-medium" : "text-paragraph",
                )}
              >
                {file.fileName}
              </span>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
