import { StrictMode } from "react"
import ReactDOM from "react-dom/client"
import { RouterProvider, createRouter } from "@tanstack/react-router"
import "./index.css"
// Import the generated route tree
import { routeTree } from "./routeTree.gen"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { I18nProvider } from "@lingui/react"
import { i18n } from "@lingui/core"
import { useAppStore } from "./state"
import { messages } from "@src/locales/en/messages"

import { DialogQueueProvider } from "@src/context/DialogQueueProvider"

const queryClient = new QueryClient()

export type RouterContext = {
  queryClient: QueryClient
  useAppStore: typeof useAppStore
}

const router = createRouter({
  routeTree,
  context: {
    queryClient,
    useAppStore,
  },
  defaultPreload: "intent",
  // Since we're using React Query, we don't want loader calls to ever be stale
  // This will ensure that the loader is always called when the route is preloaded or visited
  defaultPreloadStaleTime: 0,
})

// Register the router instance for type safety and inference
// See https://tanstack.com/router/v1/docs/framework/react/decisions-on-dx#2-declaring-the-router-instance-for-type-inference
declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router
  }
}

i18n.load("en", messages)
i18n.activate("en")

ReactDOM.createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <QueryClientProvider client={queryClient}>
      <I18nProvider i18n={i18n}>
        <DialogQueueProvider>
          <RouterProvider router={router} />
        </DialogQueueProvider>
      </I18nProvider>
    </QueryClientProvider>
  </StrictMode>,
)
