import { useState } from "react"
import { useQuery } from "@tanstack/react-query"
import { CellContext, ColumnDef } from "@tanstack/react-table"
import { useNavigate } from "@tanstack/react-router"
import { format } from "date-fns"
import { t, Trans } from "@lingui/macro"

import { getSurveyDefinitionListQuery } from "@src/api/survey"
import { Table } from "@src/components/organisms/Table"
import { useCommunityId } from "@src/hooks/useCommunityId"
import { Button } from "@src/components/atoms/Button"
import { SearchInput } from "@src/components/atoms/SearchInput"
import { useDialogQueue } from "@src/context/useDialogQueue"

export interface SurveyTableItem {
  id: number | undefined
  name: string | null | undefined
  deadline: Date | undefined
}

export const Surveys = () => {
  const [searchValue, setSearchValue] = useState("")
  const communityId = useCommunityId()
  const navigate = useNavigate()

  const { enqueueDialog, closeCurrentDialog } = useDialogQueue()

  const { data: surveyDefinitions, isLoading } = useQuery(
    getSurveyDefinitionListQuery(communityId, searchValue),
  )

  const columns: ColumnDef<SurveyTableItem>[] = [
    {
      header: t`Name`,
      accessorKey: "name",
      size: 500,
    },
    {
      header: t`Status`,
      accessorKey: "status",
      cell: ({ row }: CellContext<SurveyTableItem, unknown>) => {
        const deadline = row.original.deadline
        if (deadline && deadline > new Date()) {
          return (
            <span className="flex items-center gap-1">
              <div className="size-2 rounded-full bg-green"></div>
              <Trans>Active Until {format(deadline, "MMM yyyy")}</Trans>
            </span>
          )
        }

        return (
          <span className="flex items-center gap-1">
            <div className="size-2 rounded-full bg-red"></div>
            <Trans>Closed Since {format(deadline || "", "MMM yyyy")}</Trans>
          </span>
        )
      },
    },
    {
      header: t`Actions`,
      accessorKey: "actions",
      cell: ({ row }: CellContext<SurveyTableItem, unknown>) => {
        const id = row.original.id

        return (
          <span className="flex h-4">
            <Button
              onClick={() => {
                navigator.clipboard.writeText(
                  `${window.location.origin}/community/${communityId}/admin-panel/surveys/${id}/complete`,
                )
              }}
              className="pl-0"
              variant="text"
              icon="content_copy"
              small
            >
              <Trans>Invite link</Trans>
            </Button>
            <Button
              onClick={() => {
                navigate({
                  to: "/community/$communityId/admin-panel/surveys/$surveyId/responses",
                  params: {
                    communityId: String(communityId),
                    surveyId: String(id),
                  },
                })
              }}
              className="pl-0"
              variant="text"
              icon="timeline"
              small
            >
              <Trans>Responses</Trans>
            </Button>
            <Button
              variant="text"
              icon="edit"
              className="pl-0"
              small
              onClick={() => {
                navigate({
                  to: "/community/$communityId/admin-panel/surveys/$surveyId",
                  params: {
                    communityId: String(communityId),
                    surveyId: String(id),
                  },
                  search: { duplicate: false },
                })
              }}
            >
              <Trans>Edit</Trans>
            </Button>
            <Button
              variant="text"
              icon="file_copy"
              small
              className="pl-0"
              onClick={() => {
                navigate({
                  to: "/community/$communityId/admin-panel/surveys/$surveyId",
                  params: {
                    communityId: String(communityId),
                    surveyId: String(id),
                  },
                  search: { duplicate: true },
                })
              }}
            >
              <Trans>Duplicate</Trans>
            </Button>
            <Button
              variant="text"
              icon="delete"
              small
              className="pl-0"
              onClick={() => {
                enqueueDialog({
                  title: t`Are you sure you want to delete the ${row.original.name} survey?`,
                  actions: (
                    <div className="flex justify-end gap-2">
                      <Button
                        small
                        variant="secondary"
                        onClick={closeCurrentDialog}
                      >
                        <Trans>Cancel</Trans>
                      </Button>

                      <Button
                        small
                        onClick={() => {
                          console.log("Button clicked")
                        }}
                      >
                        <Trans>Delete</Trans>
                      </Button>
                    </div>
                  ),
                })
              }}
            >
              <Trans>Delete</Trans>
            </Button>
          </span>
        )
      },
    },
  ]

  const data =
    surveyDefinitions?.map(({ id, title, deadline }) => ({
      id,
      name: title,
      deadline: deadline,
    })) || []

  return (
    <div className="flex flex-col gap-6">
      <div className="flex gap-3">
        <Button
          small
          onClick={() => {
            navigate({
              to: "/community/$communityId/admin-panel/surveys/create",
              params: {
                communityId: String(communityId),
              },
            })
          }}
        >
          <Trans>Create new survey</Trans>
        </Button>
        <SearchInput
          name="search"
          value={searchValue}
          onChange={(value: string) => setSearchValue(value)}
        />
      </div>
      <Table columns={columns} data={data} loading={isLoading} />
    </div>
  )
}
