import { client } from "./client"
import { safeQueryOptions } from "@src/utils/query"
import { UnpackPromise, UnpackArray } from "@src/utils/types"

export const tagKeys = {
  all: ["tags"],
  communityTags: (id?: number) => [...tagKeys.all, "list", "community", id],
}

export const getCommunityTags = async (id: number) => {
  const data = await client.GET("/api/Tag/GetUserTagNameListByCommunity", {
    params: {
      query: {
        communityId: id,
      },
    },
  })

  return data
}

export type CommunityCircleDetail = UnpackArray<
  UnpackPromise<ReturnType<typeof getCommunityTags>>
>

export const getCommunityTagsQuery = (id?: number) =>
  safeQueryOptions({
    queryFn: getCommunityTags,
    queryKeys: tagKeys.communityTags(),
    unsafeQueryFnArgs: [id],
  })
