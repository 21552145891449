import clsx from "clsx"
import { Outlet, useLocation } from "@tanstack/react-router"

import { Header } from "@src/components/organisms/Header"
import { CommunityMenu } from "@src/components/organisms/Menu/CommunityMenu"
import { MobileMenu } from "@src/components/organisms/Menu/MobileMenu"
import { CommunityBreadcrumbs } from "@src/components/organisms/Breadcrumbs/CommunityBreadcrumbs"

export const CommunityLayout = () => {
  const location = useLocation()
  const isAdminPanel = location.pathname.includes("admin-panel")

  return (
    <div className="min-h-dvh w-full">
      <Header />
      <div className="flex min-h-[calc(100dvh-84px)]">
        <CommunityMenu isAdminPanel={isAdminPanel} />
        <main
          className={clsx(
            "h-[calc(100vh-97px)] w-full overflow-auto bg-pearl p-4 lg:h-[calc(100vh-67px)] lg:p-6",
            isAdminPanel && "lg:max-w-[calc(100%-270px)]",
            !isAdminPanel && "lg:max-w-[calc(100%-210px)]",
          )}
        >
          <CommunityBreadcrumbs isAdminPanel={isAdminPanel} />
          <Outlet />
        </main>
      </div>
      <MobileMenu isAdminPanel={isAdminPanel} />
    </div>
  )
}
