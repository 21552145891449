import { Select } from "@src/components/atoms/Select"
import { AnswerProps } from "./types"
import { useValidators } from "./validators"

export const DropdownAnswer = ({ form, question }: AnswerProps) => {
  const { requiredNumber } = useValidators()
  const questionOrder = question?.questionOrder ?? 0

  const options =
    question?.questionOptions?.map(
      ({ questionOption, questionOptionOrder }) => ({
        id: questionOptionOrder ?? 0,
        label: questionOption ?? "",
      }),
    ) || []

  const handleSelect = (selectedOption: { id: number }) => {
    const currentAnswer = form.state.values.answers?.[questionOrder] ?? {}

    form.setFieldValue("answers", {
      ...form.state.values.answers,
      [questionOrder]: {
        ...currentAnswer,
        optionAnswerOrder: selectedOption.id,
        formQuestionId: question.id,
      },
    })
  }
  return (
    <form.Field
      name={`answers[${questionOrder}].optionAnswerOrder`}
      validators={question.mandatoryAnswer ? requiredNumber : undefined}
    >
      {(field) => (
        <Select
          error={field.state.meta.errors.join(", ")}
          placeholder={question.title ?? ""}
          label={question.title ?? ""}
          description={question.description ?? ""}
          required={question.mandatoryAnswer}
          options={options}
          onSelect={handleSelect}
          value={options.find(({ id }) => id === field.state.value) || null}
          onBlur={field.handleBlur}
        />
      )}
    </form.Field>
  )
}
