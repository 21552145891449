import {
  Textarea as HeadlessTextarea,
  type TextareaProps as HeadlessTextareaProps,
} from "@headlessui/react"
import clsx from "clsx"

import {
  Label,
  Field,
  ErrorMessage,
  LeftIcon,
  RightIcon,
} from "@src/components/atoms/Fieldset"
import { IconType } from "@src/config/icons"

const styles = {
  base: [
    "relative block w-full appearance-none rounded-xl border bg-white", // base
    "placeholder:text-pearl-dark text-paragraph-medium placeholder:text-paragraph-medium", // typography
    "focus:outline-none", // hide default focus styles
    "data-[invalid]:border-red", // states
  ],
  primary: [
    "border-pearl-lighter focus:border-pearl-light bg-transparent text-black",
  ],
  secondary: [
    "bg-pearl-lighter/10 border-pearl-lighter/10 focus:border-pearl-lighter text-black",
  ],
  ternary: [
    "bg-ghost-white border-transparent text-black placeholder:text-gray-dark",
  ],
  withLeftIcon: ["pl-10"],
  withRightIcon: ["pr-10"],
  size: (small: boolean) => (small ? ["py-1 px-3"] : ["py-2 px-5"]),
}

export type TextareaProps = Omit<HeadlessTextareaProps, "value"> & {
  variant?: "primary" | "secondary" | "ternary"
  className?: string
  label?: string
  iconLeft?: IconType
  iconRight?: IconType
  small?: boolean
  error?: string
  description?: string
  required?: boolean
  name: string
  value?: string | number | string[] | null
}

export const Textarea = ({
  variant = "primary",
  small = false,
  label,
  error,
  iconLeft,
  iconRight,
  className,
  description,
  required,
  name,
  value,
  ...props
}: TextareaProps) => {
  return (
    <Field className={clsx("flex-col", className)}>
      <Label htmlFor={name} required={required} description={description}>
        {label}
      </Label>

      <div className="relative">
        <HeadlessTextarea
          className={clsx(
            styles.base,
            styles[variant],
            styles.size(small),
            iconLeft && styles.withLeftIcon,
            iconRight && styles.withRightIcon,
          )}
          invalid={!!error}
          name={name}
          // This is an unfortunate workaround because the type of value in HeadlessTextareaProps
          // doesn't allow null and almost all our APIs can return null for empty values
          value={value as string | number | string[] | undefined}
          {...props}
        />
        <LeftIcon icon={iconLeft} />
        <RightIcon icon={iconRight} />
      </div>
      <ErrorMessage>{error}</ErrorMessage>
    </Field>
  )
}
