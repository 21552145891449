import {
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
} from "@headlessui/react"
import clsx from "clsx"
import { t } from "@lingui/macro"

import { Field, Label, ErrorMessage } from "@src/components/atoms/Fieldset"
import { Icon } from "@src/components/atoms/Icon"
import { Checkbox } from "@src/components/atoms/Checkbox"
import { ApiDynamicFilterType } from "@src/api/filters"
import { RadioButtonList } from "@src/components/atoms/RadioButtonList"
import {
  commonStyles,
  FieldProps,
  OptionProps,
} from "@src/components/atoms/Fieldset/common"

export type AdvancedFilterOption = {
  id: ApiDynamicFilterType
  label: string
}

export type MultiSelectProps<T extends number | string> = FieldProps & {
  options: OptionProps<T>[]
  value?: OptionProps<T>[]
  onSelect: (selectedOptions: OptionProps<T>[]) => void
  advancedFilterValue?: ApiDynamicFilterType
  onFilterChange?: (filterOption: AdvancedFilterOption) => void
}

const AdvancedFilterOptions = () => [
  { id: ApiDynamicFilterType.IsAnyOf, label: t`Is any of` },
  { id: ApiDynamicFilterType.IsNoneOf, label: t`Is none of` },
]

export const MultiSelect = <T extends number | string>({
  variant = "primary",
  label,
  placeholder,
  error,
  value = [],
  small = false,
  options,
  className,
  onSelect,
  disabled,
  advancedFilterValue,
  onFilterChange,
}: MultiSelectProps<T>) => {
  const isSelected = (option: OptionProps<T>) =>
    value.some((selected) => selected.id === option.id)

  const toggleSelection = (option: OptionProps<T>) => {
    const newSelection = isSelected(option)
      ? value.filter((selected) => selected.id !== option.id)
      : [...value, option]

    onSelect(newSelection)
  }

  const hasAdvancedFilter =
    advancedFilterValue !== undefined && onFilterChange !== undefined

  return (
    <Field className={clsx("flex-col", className)}>
      <Label htmlFor={label}>{label}</Label>

      <Listbox
        value={value}
        onChange={(options: OptionProps<T>[]) => onSelect(options)}
        multiple
        disabled={disabled}
        invalid={!!error}
      >
        <ListboxButton
          className={clsx(
            commonStyles.base,
            commonStyles[variant],
            small ? ["gap-2 py-1 pl-3 pr-1"] : ["gap-4 py-2 pl-5 pr-2"],
          )}
        >
          <div className="flex flex-wrap gap-1">
            {value.length > 0 ? (
              value.map((option) => (
                <span
                  key={option.id}
                  className="text-paragraph-medium after:content-[','] last:after:content-['']"
                >
                  {option.label}
                </span>
              ))
            ) : (
              <span className="text-gray">{placeholder}</span>
            )}
          </div>
          <Icon
            className="group pointer-events-none text-black"
            icon="keyboard_arrow_down"
            size="large"
          />
        </ListboxButton>
        <ListboxOptions
          anchor="bottom"
          transition
          className={clsx(commonStyles.options)}
        >
          {hasAdvancedFilter && (
            <RadioButtonList
              options={AdvancedFilterOptions()}
              value={advancedFilterValue}
              onChange={onFilterChange}
              className="border-b border-pearl-lighter px-4 pb-3 pt-2"
            />
          )}
          <div className="py-2">
            {options.map((option) => (
              <ListboxOption
                key={option.id}
                value={option}
                onClick={() => toggleSelection(option)}
                disabled={option.disabled}
                className={clsx(commonStyles.option)}
              >
                <Checkbox
                  checked={isSelected(option)}
                  onChange={() => toggleSelection(option)}
                />
                {option.label}
              </ListboxOption>
            ))}
          </div>
        </ListboxOptions>
      </Listbox>
      <ErrorMessage>{error}</ErrorMessage>
    </Field>
  )
}
