import { Link } from "@tanstack/react-router"
import { clsx } from "clsx"

import { Image } from "@src/components/atoms/Image"
import { Skeleton } from "@src/components/atoms/Skeleton"
import { Tooltip } from "@src/components/atoms/Tooltip"

export type UserPictureProps = {
  className?: string
  src?: string | null
  name?: string
  link: string
}

export const UserPicture = ({
  src,
  name,
  link,
  className,
}: UserPictureProps) => {
  if (!src) {
    return <Skeleton className={clsx([className, "rounded-full"])} />
  }

  return (
    <Tooltip content={name} position="top">
      <Link to={link}>
        <Image
          className={clsx([className, "size-6 rounded-full"])}
          src={src}
          alt={name || "User Profile Photo"}
        />
      </Link>
    </Tooltip>
  )
}
