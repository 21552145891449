import { Table, flexRender } from "@tanstack/react-table"

import { Accordion } from "@src/components/atoms/Accordion"
import { Empty } from "@src/components/atoms/Empty"

export type MobileTableProps<T> = {
  table: Table<T>
}

export const MobileTable = <T extends object>({
  table,
}: MobileTableProps<T>) => {
  const tableHeaders = table.getHeaderGroups()[0].headers

  return (
    <div className="flex flex-col gap-2" role="table">
      {table.getRowModel().rows.length === 0 && <Empty className="bg-white" />}
      {table.getRowModel().rows.map((row) => (
        <Accordion
          key={row.id}
          title={flexRender(
            row.getVisibleCells()[0].column.columnDef.cell,
            row.getVisibleCells()[0].getContext(),
          )}
        >
          {row
            .getVisibleCells()
            .slice(1)
            .map((cell, index) =>
              cell.column.id === "actions" ? (
                <div
                  key={cell.id}
                  className="mt-5 border-t border-pearl-lighter pt-5"
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </div>
              ) : (
                <div
                  key={cell.id}
                  className="cols-4 grid grid-flow-col items-center gap-2"
                >
                  <span className="col-span-1 text-paragraph-medium font-semibold">
                    {flexRender(
                      tableHeaders[index + 1].column.columnDef.header,
                      tableHeaders[index + 1].getContext(),
                    )}
                  </span>

                  <span className="col-span-3 text-paragraph-medium font-light">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </span>
                </div>
              ),
            )}
        </Accordion>
      ))}
    </div>
  )
}
