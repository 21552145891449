import { useState } from "react"
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query"
import { CellContext, ColumnDef } from "@tanstack/react-table"
import { useNavigate } from "@tanstack/react-router"
import { format } from "date-fns"
import { t, Trans } from "@lingui/macro"

import {
  getCommunityApplicationDefinitionsQuery,
  deleteCommunityApplicationDefinition,
  applicationDefinitionKeys,
} from "@src/api/application"
import { Table } from "@src/components/organisms/Table"
import { useCommunityId } from "@src/hooks/useCommunityId"
import { Button } from "@src/components/atoms/Button"
import { SearchInput } from "@src/components/atoms/SearchInput"
import { useDialogQueue } from "@src/context/useDialogQueue"

export interface ApplicationTableItem {
  id: number | undefined
  name: string | null | undefined
  endDate: string | null | undefined
}

const initialPageSize = 10
export const Applications = () => {
  const queryClient = useQueryClient()
  const [searchValue, setSearchValue] = useState("")
  const [pageIndex, setPageIndex] = useState(0)
  const [pageSize, setPageSize] = useState(initialPageSize)
  const communityId = useCommunityId()
  const navigate = useNavigate()

  const { enqueueDialog, closeCurrentDialog } = useDialogQueue()

  const { data: applicationDefinitions, isLoading } = useQuery(
    getCommunityApplicationDefinitionsQuery(communityId),
  )

  const { mutate: deleteApplicationMutation, isPending: isPendingDeletion } =
    useMutation({
      mutationFn: (applicationId: number) => {
        return deleteCommunityApplicationDefinition(applicationId)
      },
      onSuccess: () => {
        closeCurrentDialog()

        queryClient.invalidateQueries({
          queryKey: applicationDefinitionKeys.communityList(communityId),
        })
      },
    })

  const columns: ColumnDef<ApplicationTableItem>[] = [
    {
      header: t`Name`,
      accessorKey: "name",
      size: 500,
    },
    {
      header: t`Status`,
      accessorKey: "status",
      cell: ({ row }: CellContext<ApplicationTableItem, unknown>) => {
        const endDate = row.original.endDate

        if (endDate && new Date(endDate) > new Date()) {
          return (
            <span className="flex items-center gap-1">
              <div className="size-2 rounded-full bg-green"></div>
              <Trans>Active Until {format(endDate, "MMM yyyy")}</Trans>
            </span>
          )
        }

        return (
          <span className="flex items-center gap-1">
            <div className="size-2 rounded-full bg-red"></div>
            <Trans>Inactive Since {format(endDate || "", "MMM yyyy")}</Trans>
          </span>
        )
      },
    },
    {
      header: t`Actions`,
      accessorKey: "actions",
      cell: ({ row }: CellContext<ApplicationTableItem, unknown>) => {
        const id = row.original.id || 0

        return (
          <span className="flex h-4">
            <Button
              onClick={() => {
                navigator.clipboard.writeText(
                  `${window.location.origin}/application-definition/${id}`,
                )
              }}
              className="pl-0"
              variant="text"
              icon="content_copy"
              small
            >
              <Trans>Invite link</Trans>
            </Button>
            <Button
              onClick={() => {
                navigate({
                  to: "/community/$communityId/admin-panel/application-definitions/$applicationId/responses",
                  params: {
                    communityId: String(communityId),
                    applicationId: String(id),
                  },
                })
              }}
              className="pl-0"
              variant="text"
              icon="timeline"
              small
            >
              <Trans>Responses</Trans>
            </Button>
            <Button
              variant="text"
              icon="edit"
              className="pl-0"
              small
              onClick={() => {
                navigate({
                  to: "/community/$communityId/admin-panel/application-definitions/$applicationId",
                  params: {
                    communityId: String(communityId),
                    applicationId: String(id),
                  },
                  search: { duplicate: false },
                })
              }}
            >
              <Trans>Edit</Trans>
            </Button>
            <Button
              variant="text"
              icon="file_copy"
              small
              className="pl-0"
              onClick={() => {
                navigate({
                  to: "/community/$communityId/admin-panel/application-definitions/$applicationId",
                  params: {
                    communityId: String(communityId),
                    applicationId: String(id),
                  },
                  search: { duplicate: true },
                })
              }}
            >
              <Trans>Duplicate</Trans>
            </Button>
            <Button
              variant="text"
              icon="delete"
              small
              className="pl-0"
              disabled={isPendingDeletion}
              onClick={() => {
                enqueueDialog({
                  title: t`Are you sure you want to delete the ${row.original.name} application?`,
                  actions: (
                    <div className="flex justify-end gap-2">
                      <Button
                        small
                        variant="secondary"
                        onClick={closeCurrentDialog}
                      >
                        <Trans>Cancel</Trans>
                      </Button>

                      <Button
                        small
                        onClick={() => {
                          deleteApplicationMutation(id)
                        }}
                      >
                        <Trans>Delete</Trans>
                      </Button>
                    </div>
                  ),
                })
              }}
            >
              <Trans>Delete</Trans>
            </Button>
          </span>
        )
      },
    },
  ]

  const filteredApplications =
    applicationDefinitions?.list
      ?.filter(({ name }) =>
        name?.toLowerCase().includes(searchValue.toLowerCase()),
      )
      .map(({ id, name, endDate }) => ({
        id,
        name,
        endDate,
      })) ?? []

  const data = filteredApplications.slice(
    pageIndex * pageSize,
    (pageIndex + 1) * pageSize,
  )

  return (
    <div className="flex flex-col gap-6">
      <div className="flex gap-3">
        <Button
          small
          onClick={() => {
            navigate({
              to: "/community/$communityId/admin-panel/application-definitions/create",
              params: {
                communityId: String(communityId),
              },
            })
          }}
        >
          <Trans>Create new application</Trans>
        </Button>
        <SearchInput
          name="search"
          value={searchValue}
          onChange={(value: string) => {
            setPageIndex(0)
            setSearchValue(value)
          }}
        />
      </div>
      <Table
        columns={columns}
        data={data}
        loading={isLoading}
        itemsCount={filteredApplications.length}
        pageIndex={pageIndex}
        pageSize={pageSize}
        onPageChange={setPageIndex}
        onPageSizeChange={setPageSize}
      />
    </div>
  )
}
