import isEqual from "lodash.isequal"

import {
  ApiDynamicFilter,
  ApiFilters,
  QuestionFilter,
  QuestionDynamicFilter,
  ApiDynamicFilterType,
} from "@src/api/filters"

export const cleanQuestionFilters = (
  filters: Array<QuestionFilter | QuestionDynamicFilter> | null | undefined,
) => {
  if (!filters) {
    return null
  }

  return filters.filter((filter) => {
    return (
      filter?.answers?.length > 0 ||
      (filter as QuestionDynamicFilter)?.filterType !==
        ApiDynamicFilterType.IsAnyOf
    )
  })
}

// Util function to keep the search params clean if the filters are empty or match the default values
export const cleanFilters = (
  filters: ApiFilters,
  defaultValues: Partial<ApiFilters> = {},
) => {
  const cleanedFilters = { ...filters }

  Object.keys(cleanedFilters).forEach((key) => {
    if (cleanedFilters[key] === undefined || cleanedFilters[key] === null) {
      delete cleanedFilters[key]
    }

    if (isEqual(cleanedFilters[key], defaultValues[key])) {
      delete cleanedFilters[key]
    }

    // If we don't have any options selected and the filter type is IsAnyOf, which is
    // the default, then these are the default values and we don't need to pollute the
    // search params with them
    if (
      typeof cleanedFilters[key] === "object" &&
      !Array.isArray(cleanedFilters[key])
    ) {
      if (
        !(cleanedFilters[key] as ApiDynamicFilter<string | number>)?.values
          ?.length &&
        (!(cleanedFilters[key] as ApiDynamicFilter<string | number>)
          ?.filterType ||
          (cleanedFilters[key] as ApiDynamicFilter<string | number>)
            ?.filterType === ApiDynamicFilterType.IsAnyOf)
      ) {
        delete cleanedFilters[key]
      }
    }

    if (key === "filters") {
      cleanedFilters.filters = cleanQuestionFilters(cleanedFilters.filters)

      if (!cleanedFilters.filters || cleanedFilters.filters?.length === 0) {
        delete cleanedFilters.filters
      }
    }
  })

  return cleanedFilters
}
