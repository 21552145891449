import { queryOptions } from "@tanstack/react-query"
import { client } from "./client"
import { safeQueryOptions } from "@src/utils/query"
import { UnpackApiListPromise } from "@src/utils/types"
import { components } from "./schema"

export enum ApplicationStatus {
  Apply = 0,
  Draft = 1,
  Pending = 2,
  Accepted = 3,
  Denied = 4,
}

export const applicationKeys = {
  all: ["applications"],
  userList: () => [...applicationKeys.all, "list", "user"],
}

export const applicationDefinitionKeys = {
  all: ["applicationDefinitions"],
  communityList: (id?: number) => [
    ...applicationDefinitionKeys.all,
    "list",
    "community",
    id,
  ],
  applicationDefinition: (id?: number) => [
    ...applicationKeys.all,
    "definition",
    id,
  ],
}

export type CommunityApplicationDetail = UnpackApiListPromise<
  ReturnType<typeof getCommunityApplicationDefinitions>
> & { evaluationRequired?: boolean }
// @todo @Tibs lets take a look at this one

export const getCommunityApplicationDefinitions = async (id: number) => {
  const data = await client.GET("/api/Community/{id}/ApplicationDefinitions", {
    params: {
      path: { id },
    },
  })

  return data
}

export const getCommunityApplicationDefinitionsQuery = (id?: number) =>
  safeQueryOptions({
    queryFn: getCommunityApplicationDefinitions,
    queryKeys: applicationDefinitionKeys.communityList(id),
    unsafeQueryFnArgs: [id],
  })

export const getCommunityApplicationDefinition = async (id: number) => {
  const data = await client.GET("/api/ApplicationDefinition/{id}", {
    params: {
      path: { id },
    },
  })

  return data
}

export const getCommunityApplicationDefinitionQuery = (id?: number) =>
  safeQueryOptions({
    queryFn: getCommunityApplicationDefinition,
    queryKeys: applicationDefinitionKeys.applicationDefinition(id),
    unsafeQueryFnArgs: [id],
  })

export const deleteCommunityApplicationDefinition = async (id: number) => {
  const data = await client.DELETE("/api/ApplicationDefinition/{id}", {
    params: {
      path: { id },
    },
  })

  return data
}

export const saveCommunityApplicationDefinition = async (
  payload: CommunityApplicationDetail,
  id?: number,
) => {
  if (!id) {
    const data = await client.POST("/api/ApplicationDefinition", {
      body: payload,
    })

    return data
  }

  const data = await client.PUT("/api/ApplicationDefinition/{id}", {
    params: {
      path: { id },
    },
    body: payload,
  })

  return data
}

export const getCurrentUserApplications = async () => {
  const data = await client.GET("/api/Application/GetCurrentUserApplications")

  return data
}

export const getCurrentUserApplicationsQuery = () =>
  queryOptions({
    queryKey: ["currentUserApplications", "list"],
    queryFn: () => getCurrentUserApplications(),
  })

export type CurrentUserApplication = UnpackApiListPromise<
  ReturnType<typeof getCurrentUserApplications>
>

export type ApplicationsFilter = components["schemas"]["ApplicationFilter"]

export const getApplicationDefinitionApplications = async (
  id: number,
  filters: ApplicationsFilter,
) => {
  const data = await client.POST(
    "/api/ApplicationDefinition/{id}/Applications",
    {
      params: {
        path: { id },
      },
      body: filters,
    },
  )

  return data
}
