import { lazy, Suspense, useEffect, useState } from "react"
import { useMutation, useQuery } from "@tanstack/react-query"
import { useForm } from "@tanstack/react-form"
import { zodValidator } from "@tanstack/zod-form-adapter"
import { t, Trans } from "@lingui/macro"
import { z } from "zod"
import { useNavigate } from "@tanstack/react-router"

import { useCommunityId } from "@src/hooks/useCommunityId"

import {
  saveSurveyDefinition,
  SurveyDefinition,
  RecurrenceType,
  SurveyType,
} from "@src/api/survey"
import { getCommunityFormsQuery } from "@src/api/form"

import { ZodValidator } from "@src/components/organisms/GenericForm"
import { Input } from "@src/components/atoms/Input"
import { Button } from "@src/components/atoms/Button"
import { InfoContainer } from "@src/components/atoms/InfoContainer"
import { Select } from "@src/components/atoms/Select"
import { DatePicker } from "@src/components/atoms/DatePicker"
import { PrivacyUserSelector } from "@src/components/organisms/PrivacyUserSelector"

const Editor = lazy(() =>
  import("@src/components/organisms/Editor").then((module) => ({
    default: module.Editor,
  })),
)

export type SurveyDefinitionPageProps = {
  survey?: SurveyDefinition
  duplicate?: boolean
}

export const SurveyDefinitionForm = ({
  survey,
  duplicate = false,
}: SurveyDefinitionPageProps) => {
  const navigate = useNavigate()
  const communityId = useCommunityId()
  const [showInfo, setShowInfo] = useState(true)

  const validators = {
    name: {
      onBlur: z
        .string()
        .min(3, t`The form name must be at least 3 characters`)
        .max(80, t`The form name must be under 80 characters`),
    },
    requiredString: {
      onBlur: z
        .string({
          required_error: t`This field is required`,
        })
        .refine((value) => value.length >= 0),
    },
    requiredNumber: {
      onBlur: z.number({
        required_error: t`This field is required`,
      }),
    },
    requiredDate: {
      onBlur: z.date({
        required_error: t`This field is required`,
      }),
    },
  }

  const { data: forms } = useQuery(getCommunityFormsQuery(communityId))
  const formOptions =
    forms?.list?.map(({ id, name }) => ({
      id: id as number,
      label: name as string,
    })) || []

  const recurrenceOptions = [
    {
      id: RecurrenceType.Once,
      label: t`Just once`,
    },
    {
      id: RecurrenceType.OncePerWeek,
      label: t`Once per week`,
    },
    {
      id: RecurrenceType.OncePerMonth,
      label: t`Once per month`,
    },
    {
      id: RecurrenceType.OncePerQuarter,
      label: t`Once per quarter`,
    },
    {
      id: RecurrenceType.OncePerYear,
      label: t`Once per year`,
    },
  ]

  const surveyOptions = [
    {
      id: SurveyType.Startup,
      label: t`Startup Survey`,
    },
    {
      id: SurveyType.Individual,
      label: t`User survey`,
    },
  ]

  const { mutate: saveSurveyDefinitionMutation } = useMutation({
    mutationFn: (payload: SurveyDefinition) => {
      if (duplicate) {
        return saveSurveyDefinition({
          ...payload,
          id: 0,
        })
      } else {
        return saveSurveyDefinition(payload, survey?.id)
      }
    },
  })

  const formInstance = useForm<SurveyDefinition, ZodValidator>({
    defaultValues: survey,
    validatorAdapter: zodValidator(),
    onSubmit: async ({ value }) => {
      return saveSurveyDefinitionMutation(value, {
        onSuccess: (response) => {
          if (!survey?.id || duplicate) {
            navigate({
              to: "/community/$communityId/admin-panel/surveys/$surveyId",
              params: {
                communityId: String(communityId),
                surveyId: String(response.id),
              },
              search: { duplicate: false },
            })
          }
        },
      })
    },
  })

  useEffect(() => {
    formInstance.reset(survey)
  }, [formInstance, survey])

  return (
    <form
      className="m-auto"
      onSubmit={(e) => {
        e.preventDefault()
        e.stopPropagation()
        formInstance.handleSubmit()
      }}
    >
      <div className="flex flex-col gap-6">
        <header className="flex items-center justify-end gap-2">
          <Button
            variant="secondary"
            small
            onClick={() => {
              navigate({
                to: "/community/$communityId/admin-panel/surveys",
                params: {
                  communityId: String(communityId),
                },
              })
            }}
          >
            <Trans>Cancel</Trans>
          </Button>
          <formInstance.Subscribe
            selector={(state) => [state.canSubmit, state.isSubmitting]}
            children={([canSubmit, isSubmitting]) => (
              <Button small type="submit" disabled={!canSubmit || isSubmitting}>
                <Trans>Save Changes</Trans>
              </Button>
            )}
          />
        </header>
        <section className="w-full rounded-xl border border-pearl-lighter bg-white p-6">
          <formInstance.Field
            name="title"
            validators={validators.name}
            children={(field) => (
              <Input
                name="name"
                placeholder={t`Name`}
                error={field.state.meta.errors.join(", ")}
                variant="ternary"
                onChange={(value) => field.handleChange(value as string)}
                value={field.state.value}
                onBlur={field.handleBlur}
              />
            )}
          />
          <div className="my-4 h-[1px] w-full bg-pearl-lighter"></div>
          <Suspense fallback={<div>Loading editor...</div>}>
            <formInstance.Field
              name="description"
              children={(field) => (
                <Editor
                  className="rounded-xl bg-ghost-white"
                  placeholder={t`Write a compelling description to explain to users how they should dill the form`}
                  value={field.state.value}
                  onChange={(value) => {
                    field.handleChange(value)
                  }}
                />
              )}
            />
          </Suspense>
        </section>

        {showInfo && (
          <InfoContainer
            type="default"
            text={t`In case the survey targets startups, is it enough that only 1 team member fills the survey. Any team member can continue and contribute to filling the survey.`}
            onClose={() => setShowInfo(false)}
          />
        )}

        <formInstance.Field
          name="surveyType"
          validators={validators.requiredNumber}
        >
          {(field) => (
            <Select
              error={field.state.meta.errors.join(", ")}
              insideLabel={t`Survey type`}
              placeholder={t`Please select a type`}
              required
              className="lg:w-1/3"
              options={surveyOptions}
              onSelect={({ id }) => {
                field.handleChange(id as 1 | 2)
              }}
              value={
                surveyOptions.find(({ id }) => id === field.state.value) || null
              }
              onBlur={field.handleBlur}
            />
          )}
        </formInstance.Field>

        <formInstance.Subscribe
          selector={(state) => ({
            circleList: state.values.circleList,
            tags: state.values.tags,
            surveyType: state.values.surveyType,
          })}
          children={({ circleList, tags, surveyType }) =>
            surveyType === SurveyType.Individual && (
              <PrivacyUserSelector
                onChange={({ circleIds, tags }) => {
                  formInstance.setFieldValue("circleList", circleIds)
                  formInstance.setFieldValue("tags", tags)
                }}
                circleIds={circleList}
                tags={tags}
              />
            )
          }
        />

        <section className="flex w-full flex-col gap-6 rounded-xl border border-pearl-lighter bg-white p-6">
          <div className="flex items-end gap-3 lg:w-2/3">
            <formInstance.Field
              name="formId"
              validators={validators.requiredNumber}
            >
              {(field) => (
                <Select
                  error={field.state.meta.errors.join(", ")}
                  label={t`Choose a form`}
                  placeholder={t`Please select a form`}
                  required
                  className="w-full"
                  options={formOptions}
                  onSelect={({ id }) => {
                    field.handleChange(id as number)
                  }}
                  value={
                    formOptions.find(({ id }) => id === field.state.value) ||
                    null
                  }
                  onBlur={field.handleBlur}
                />
              )}
            </formInstance.Field>
            <span className="pb-2 text-paragraph-medium text-pearl-dark">
              Or
            </span>
            <Button
              variant="ternary"
              small
              icon="description"
              className="w-full"
              onClick={() => {
                navigate({
                  to: "/community/$communityId/admin-panel/surveys/create",
                  params: {
                    communityId: String(communityId),
                  },
                })
              }}
            >
              <Trans>Create a new form</Trans>
            </Button>
          </div>

          <formInstance.Field
            name="recurrence"
            validators={validators.requiredNumber}
          >
            {(field) => (
              <Select
                error={field.state.meta.errors.join(", ")}
                label={t`Recurring survey`}
                placeholder={t`Please select the recurrence`}
                required
                className="lg:w-2/3"
                options={recurrenceOptions}
                onSelect={({ id }) => {
                  field.handleChange(id)
                }}
                value={
                  recurrenceOptions.find(
                    ({ id }) => id === field.state.value,
                  ) || null
                }
                onBlur={field.handleBlur}
              />
            )}
          </formInstance.Field>

          <div className="flex items-baseline gap-3 lg:w-2/3">
            <formInstance.Field
              name="startDate"
              validators={validators.requiredDate}
            >
              {(field) => (
                <DatePicker
                  name="startDate"
                  error={field.state.meta.errors.join(", ")}
                  label={`Start date & time`}
                  className="w-full"
                  required
                  hasTimePicker
                  onChange={(value) => {
                    field.handleChange(value as Date)
                  }}
                  value={field.state.value || ""}
                  onBlur={field.handleBlur}
                />
              )}
            </formInstance.Field>
            <formInstance.Subscribe
              selector={(state) => ({
                recurrence: state.values.recurrence,
              })}
              children={({ recurrence }) =>
                recurrence !== RecurrenceType.Once && (
                  <formInstance.Field
                    name="endDate"
                    validators={validators.requiredDate}
                  >
                    {(field) => (
                      <DatePicker
                        name="endDate"
                        error={field.state.meta.errors.join(", ")}
                        label={`End date & time`}
                        className="w-full"
                        required
                        hasTimePicker
                        onChange={(value) => {
                          field.handleChange(value as Date)
                        }}
                        value={field.state.value}
                        onBlur={field.handleBlur}
                      />
                    )}
                  </formInstance.Field>
                )
              }
            />
          </div>

          <formInstance.Field
            name="deadline"
            validators={validators.requiredDate}
          >
            {(field) => (
              <DatePicker
                name="deadline"
                error={field.state.meta.errors.join(", ")}
                label={t`Deadline`}
                className="lg:w-2/3"
                required
                hasTimePicker
                onChange={(value) => {
                  field.handleChange(value as Date)
                }}
                value={field.state.value}
                onBlur={field.handleBlur}
              />
            )}
          </formInstance.Field>
        </section>
      </div>
    </form>
  )
}
