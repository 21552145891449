import { ReactNode } from "react"
import {
  Field as HeadlessField,
  Label as HeadlessLabel,
  Description as HeadlessDescription,
  type FieldProps as HeadlessFieldProps,
  type LabelProps as HeadlessLabelProps,
  type DescriptionProps as HeadlessDescriptionProps,
} from "@headlessui/react"
import { ValidationError } from "@tanstack/react-form"
import clsx from "clsx"

import { Icon } from "@src/components/atoms/Icon"
import { Popover } from "@src/components/atoms/Popover"
import { IconType } from "@src/config/icons"

export const Field = ({
  children,
  className,
  ...props
}: HeadlessFieldProps) => {
  return (
    <HeadlessField
      data-testid="label"
      className={clsx([
        className,
        "relative flex gap-2 has-[[data-disabled]]:opacity-50",
      ])}
      {...props}
    >
      {children}
    </HeadlessField>
  )
}

export const Label = ({
  children,
  required,
  description,
  info,
  className,
  ...props
}: HeadlessLabelProps & {
  required?: boolean
  description?: string
  info?: ReactNode
}) => {
  return (
    (children || description) && (
      <div className="flex flex-col gap-2">
        <HeadlessLabel
          data-testid="label"
          className={clsx(className, "select-none text-paragraph text-black")}
          {...props}
        >
          {
            <>
              {children as ReactNode}
              {required && (
                <span>
                  <span className="pl-1" aria-hidden="true">
                    *
                  </span>
                  <span className="sr-only">Required</span>
                </span>
              )}
              {info && (
                <Popover
                  button={<Icon icon="info" size="small" className="ml-1" />}
                  className="w-96"
                  panelContent={info}
                />
              )}
            </>
          }
        </HeadlessLabel>
        <Description>{description}</Description>
      </div>
    )
  )
}

export const ErrorMessage = ({
  children,
  ...props
}: HeadlessDescriptionProps) => {
  return (
    children && (
      <HeadlessDescription
        data-testid="error-message"
        className="text-paragraph-medium text-red"
        {...props}
      >
        {children}
      </HeadlessDescription>
    )
  )
}

export const ValidationErrorList = ({
  errors,
  className = "",
}: {
  errors: ValidationError[]
  className?: string
}) => {
  return (
    <div className={className}>
      {errors.map((error, index) => (
        <ErrorMessage key={index}>{error}</ErrorMessage>
      ))}
    </div>
  )
}

export const Description = ({
  children,
  ...props
}: HeadlessDescriptionProps) => {
  return (
    children && (
      <HeadlessDescription
        data-testid="description"
        className="text-paragraph-medium text-black-light"
        dangerouslySetInnerHTML={{ __html: children as string }}
        {...props}
      />
    )
  )
}

export const LeftIcon = ({ icon }: { icon?: IconType }) => {
  return (
    icon && (
      <Icon
        icon={icon}
        size="large"
        className={clsx([
          // base
          "flex items-center pl-3",

          // position
          "absolute bottom-[10px] left-0",

          // typography
          "pointer-events-none text-pearl-lighter",
        ])}
      />
    )
  )
}

export const RightIcon = ({ icon }: { icon?: IconType }) => {
  return (
    icon && (
      <Icon
        icon={icon}
        className={clsx([
          // base
          "flex items-center pr-3",

          // position
          "absolute bottom-[10px] right-0",

          // typography
          "pointer-events-none text-heading5 text-pearl-lighter",
        ])}
      />
    )
  )
}
