import {
  Button as HeadlessButton,
  ButtonProps as HeadlessButtonProps,
} from "@headlessui/react"
import { clsx } from "clsx"

import { Icon } from "@src/components/atoms/Icon"

import { styles, BaseButtonProps } from "./common"

export type ButtonProps = BaseButtonProps & HeadlessButtonProps

export const Button = ({
  variant = "primary",
  className,
  icon,
  small = false,
  children,
  ...props
}: ButtonProps) => {
  const classes = clsx(
    className,
    styles.base,
    styles[variant],
    styles.size(small),
  )

  return (
    <HeadlessButton className={classes} {...props}>
      {icon && <Icon size={small ? "default" : "large"} icon={icon} />}

      {children}
    </HeadlessButton>
  )
}
