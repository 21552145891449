import { useMemo } from "react"
import { useQuery } from "@tanstack/react-query"
import { t } from "@lingui/macro"
import clsx from "clsx"

import { getCommunityCirclesQuery } from "@src/api/circle"
import { getCommunityTagsQuery } from "@src/api/tags"
import { getCommunityUsersQuery } from "@src/api/community"

import { useCommunityId } from "@src/hooks/useCommunityId"
import { MultiLevelMultiSelect } from "./MultiLevelMultiSelect"
import { IconButton } from "@src/components/atoms/IconButton"
import { MultiCombobox } from "@src/components/atoms/MultiCombobox"
import { UserPicture } from "@src/components/molecules/UserPicture"

export type UserSelectorProps = {
  className?: string
  userIds: number[]
  onChange: (userIds: number[]) => void
}

export const UserSelector = ({
  className,
  userIds = [],
  onChange,
}: UserSelectorProps) => {
  const communityId = useCommunityId()

  const { data: communityCircles = [] } = useQuery(
    getCommunityCirclesQuery(communityId),
  )
  const { data: communityTags = [] } = useQuery(
    getCommunityTagsQuery(communityId),
  )
  const { data: communityUsers } = useQuery(
    getCommunityUsersQuery({
      communityId: communityId || 0,
    }),
  )

  const usersOptions = useMemo(() => {
    return (
      communityUsers?.users?.list?.map(
        ({
          id,
          firstName,
          lastName,
          photoUrl,
          communityCircleId,
          userTags,
        }) => ({
          id: id || 0,
          label: `${firstName} ${lastName}`,
          photoUrl: photoUrl,
          circleId: communityCircleId,
          userTags: userTags,
        }),
      ) || []
    )
  }, [communityUsers])

  const selectedUsers = userIds.length
    ? usersOptions
        .filter(({ id }) => userIds.includes(id))
        ?.sort((a, b) => a.label.localeCompare(b.label))
    : []

  return (
    <div className={clsx("flex flex-col gap-4", className)}>
      <div className="flex flex-col gap-3 lg:flex-row">
        <MultiCombobox
          value={selectedUsers}
          label={t`Choose your evaluators`}
          placeholder={t`Search for users`}
          options={usersOptions}
          onSelect={(values) => onChange(values.map((v) => v.id))}
        />
        <MultiLevelMultiSelect
          value={selectedUsers}
          label={t`Programs`}
          placeholder={t`Select Programs`}
          className="flex-grow"
          options={communityCircles?.map(({ id, name }) => ({
            id: id || 0,
            label: name || "",
            children: usersOptions.filter((user) => user.circleId === id),
          }))}
          onSelect={(values) => onChange(values.map((v) => v.id))}
        />
        <MultiLevelMultiSelect
          value={selectedUsers}
          label={t`Tags`}
          placeholder={t`Select Tags`}
          className="flex-grow"
          options={communityTags?.map((tag, index) => ({
            id: index,
            label: tag,
            children: usersOptions.filter((user) =>
              user?.userTags?.includes(tag),
            ),
          }))}
          onSelect={(values) => onChange(values.map((v) => v.id))}
        />
      </div>
      {selectedUsers?.length ? (
        <div className="col-span-2 text-paragraph-medium font-light text-gray md:row-start-3">
          <div className="flex flex-wrap items-center gap-2">
            {selectedUsers?.slice(0, 5).map((user) => (
              <span
                className="flex items-center gap-1 rounded-xl border border-pearl-lighter px-2 py-1"
                key={user.id}
              >
                <UserPicture
                  className="vertical-align-middle"
                  key={user.id}
                  src={user.photoUrl}
                  name={user.label}
                  link={`/user/${user.id}`}
                />

                <span className="text-paragraph-medium">{user.label}</span>

                <IconButton
                  icon="close"
                  size="small"
                  onClick={() =>
                    onChange(userIds.filter((id) => id !== user.id))
                  }
                  variant="text"
                />
              </span>
            ))}
          </div>
        </div>
      ) : null}
    </div>
  )
}
