import { useState, useEffect, useMemo } from "react"
import { Input as HeadlessInput } from "@headlessui/react"
import {
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
} from "@headlessui/react"
import clsx from "clsx"

import { Label, Field, ErrorMessage } from "@src/components/atoms/Fieldset"
import { Icon } from "@src/components/atoms/Icon"
import { commonStyles, FieldProps } from "@src/components/atoms/Fieldset/common"

type Option = {
  id: number
  label: string
}

export type CurrencyInputProps = FieldProps & {
  name: string
  value?: string | number | string[] | null
  onChange?: (value: string) => void
  type?: "text" | "number"
}

const currencies = [
  { id: 1, label: "EUR" },
  { id: 2, label: "USD" },
  { id: 3, label: "GBP" },
  { id: 4, label: "EGP" },
]

export const CurrencyInput = ({
  variant = "primary",
  small = false,
  label,
  error,
  className,
  description,
  required,
  name,
  value,
  onChange,
  ...props
}: CurrencyInputProps) => {
  const [amount, setAmount] = useState<string>("")
  const [currency, setCurrency] = useState<string>(currencies[0].label)
  const currencyOption = useMemo(
    () => currencies.find((c) => c.label === currency),
    [currency],
  )

  useEffect(() => {
    if (value) {
      const match = (value as string).match(/(\d+)([A-Za-z]+)/)
      if (match) {
        setAmount(match[1])
        setCurrency(match[2])
      }
    }
  }, [value])

  // Handle input change
  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newAmount = e.target.value.replace(/[^\d]/g, "") // Ensure only numbers
    setAmount(newAmount)
    onChange?.(`${newAmount}${currency}`)
  }

  // Handle currency selection change
  const handleCurrencyChange = ({ label }: Option) => {
    setCurrency(label)
    onChange?.(`${amount}${label}`)
  }

  return (
    <Field className={clsx("relative flex-col", className)}>
      <Label htmlFor={name} required={required} description={description}>
        {label}
      </Label>
      <div className="relative flex items-center gap-2">
        <HeadlessInput
          className={clsx(
            commonStyles.base,
            commonStyles[variant],
            small ? ["px-3 py-1"] : ["px-5 py-2"],
          )}
          invalid={!!error}
          name={name}
          value={amount}
          onChange={handleAmountChange}
          {...props}
        />
        <Listbox
          value={currencyOption}
          onChange={(option: Option) => handleCurrencyChange(option)}
        >
          <div className="absolute bottom-0 right-0 w-16">
            <ListboxButton className="flex items-center gap-1 border-l border-pearl-lighter py-2 pl-2 text-paragraph-medium">
              <span>{currencyOption?.label}</span>
              <Icon
                className="text-black"
                icon="keyboard_arrow_down"
                size="large"
              />
            </ListboxButton>
            <ListboxOptions className={clsx(commonStyles.options)}>
              {currencies.map((option) => (
                <ListboxOption
                  key={option.id}
                  value={option}
                  className={clsx(
                    "cursor-pointer",
                    option.id === currencyOption?.id && "bg-blue-light/50",
                  )}
                >
                  {option.label}
                </ListboxOption>
              ))}
            </ListboxOptions>
          </div>
        </Listbox>
      </div>
      <ErrorMessage>{error}</ErrorMessage>
    </Field>
  )
}
