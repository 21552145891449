import { useState } from "react"
import { useQuery } from "@tanstack/react-query"
import { Button as HeadlessButton } from "@headlessui/react"

import { getCommunityOverviewQuery } from "@src/api/community"
import { UserProfile } from "@src/components/molecules/UserProfile"
import { Image } from "@src/components/atoms/Image"
import { IconButton } from "@src/components/atoms/IconButton"
import { Skeleton } from "@src/components/atoms/Skeleton"
import { useCommunityId } from "@src/hooks/useCommunityId"
import { HeaderDrawer } from "./HeaderDrawer"

export const Header = () => {
  const [menuOpened, setMenuOpened] = useState(false)
  const communityId = useCommunityId()

  const { data, isLoading } = useQuery(getCommunityOverviewQuery(communityId))

  return (
    <>
      <header className="flex w-full items-center justify-between border-b-[1px] border-pearl-lighter px-2 py-1.5">
        <div className="flex gap-6">
          <IconButton
            icon="menu"
            onClick={() => setMenuOpened(true)}
            className="p-2 text-pearl-light"
            size="large"
            variant="text"
          />
          <div className="flex items-center gap-2">
            {!data || isLoading ? (
              <>
                <Skeleton className="h-7 w-7 lg:h-12 lg:w-12" />

                <div className="flex flex-col gap-1">
                  <Skeleton className="h-4 w-24" />
                  <Skeleton className="h-4 w-28" />
                </div>
              </>
            ) : (
              <>
                <Image
                  className="h-7 w-7 rounded-lg lg:h-12 lg:w-12"
                  src={data?.logoImage}
                  alt="logo image"
                />
                <div className="flex flex-col">
                  <span className="text-paragraph-small font-light uppercase lg:text-paragraph-medium">
                    Community
                  </span>
                  <span className="text-paragraph-small font-semibold lg:text-paragraph-medium">
                    {data?.name}
                  </span>
                </div>
              </>
            )}
          </div>
        </div>
        <div>
          <HeadlessButton onClick={() => {}}>
            <UserProfile className="hidden h-12 w-12 lg:block" />
          </HeadlessButton>
        </div>
      </header>
      {data && (
        <HeaderDrawer
          opened={menuOpened}
          community={data}
          closeMenu={() => setMenuOpened(false)}
        />
      )}
    </>
  )
}
