import { Radio, RadioGroup } from "@headlessui/react"
import clsx from "clsx"

import { Label, Field, ErrorMessage } from "@src/components/atoms/Fieldset"

type Option = {
  id: number
  label: string
  group?: string
  icon?: string
}

export type RadioButtonListProps = {
  options: Option[]
  label?: string
  description?: string
  error?: string
  required?: boolean
  value?: number | null
  onChange: (option: Option) => void
  onBlur?: () => void
  className?: string
  disabled?: boolean
}

export const RadioButtonList = ({
  options,
  label,
  description,
  error,
  required,
  value,
  onChange,
  onBlur,
  className,
  disabled,
}: RadioButtonListProps) => {
  return (
    <Field
      className={clsx("flex-col", className)}
      data-testid="radio-button-list-container"
    >
      <Label htmlFor={label} required={required} description={description}>
        {label}
      </Label>

      <RadioGroup
        value={value}
        disabled={disabled}
        onChange={(value) => {
          const selectedOption = options.find((option) => option.id === value)
          if (selectedOption) {
            onChange(selectedOption)
          }
        }}
        onBlur={onBlur}
        aria-label="Options"
        className={clsx(`flex flex-col gap-2`)}
      >
        {options.map((option) => (
          <Field key={option.id} className="flex items-center gap-2">
            <Radio
              value={option.id}
              className="group block size-4 rounded-full border border-pearl-lighter bg-white p-0.5 focus:outline-none"
            >
              <div className="size-2.5 rounded-full group-data-[checked]:bg-blue"></div>
            </Radio>
            <Label className="text-paragraph-medium">{option.label}</Label>
          </Field>
        ))}
      </RadioGroup>
      <ErrorMessage>{error}</ErrorMessage>
    </Field>
  )
}
