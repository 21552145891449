import { FormQuestionAnswerType } from "@src/api/form"
import { Privacy } from "@src/api/privacy"

export const DEFAULT_FORM_QUESTION = () => {
  const id = Math.floor(Math.random() * 9000000) + 1000000

  return {
    id: 0,
    formId: 0,
    title: "",
    description: "",
    questionOrder: 0,
    mandatoryAnswer: true,
    answerType: FormQuestionAnswerType.ShortTextAnswer,
    answerSubtype: "",
    allowMultipleAnswers: false,
    privacyLevel: Privacy.Public,
    // Assigning a temporary id to the question that can be used for conditional logic
    // and drag & drop logic. Easier to keep track off than array indexes, 0 ids or the
    // questionOrder property
    temporaryQuestionId: id,
  }
}

export const DEFAULT_FORM_QUESTION_OPTION = {
  id: 0,
  questionOptionOrder: 0,
}

export const DEFAULT_FORM_QUESTION_CONDITIONAL_LOGIC_OPTION = {
  id: 0,
  conditionFormQuestionId: 0,
  conditionLogic: undefined,
  customValue: "",
  formQuestionOptionId: undefined,
  temporaryQuestionId: 0,
}
