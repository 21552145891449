import { t, Trans } from "@lingui/macro"
import { lazy, Suspense } from "react"

import { SectionProps } from "./types"

const Editor = lazy(() =>
  import("@src/components/organisms/Editor").then((module) => ({
    default: module.Editor,
  })),
)

export const EmailsSection = ({ form }: SectionProps) => {
  return (
    <section className="flex w-full flex-col gap-6 rounded-xl border border-pearl-lighter bg-white p-6">
      <h5 className="font-medium">
        <Trans>Emails</Trans>
      </h5>
      <Suspense fallback={<div>Loading editor...</div>}>
        <form.Field
          name="acceptedEmail"
          children={(field) => (
            <Editor
              className="rounded-xl bg-ghost-white"
              label="Accepted application email"
              placeholder={t`Write your email here`}
              value={field.state.value}
              onChange={(value) => {
                field.handleChange(value)
              }}
            />
          )}
        />
        <form.Field
          name="rejectedEmail"
          children={(field) => (
            <Editor
              className="rounded-xl bg-ghost-white"
              label="Rejected application email"
              placeholder={t`Write your email here`}
              value={field.state.value}
              onChange={(value) => {
                field.handleChange(value)
              }}
            />
          )}
        />
      </Suspense>
    </section>
  )
}
