import { useQuery } from "@tanstack/react-query"

import { getApplicationFormQuery } from "@src/api/form"

import { Skeleton } from "@src/components/atoms/Skeleton"
import { FormForm } from "./FormForm"

export type FormPageProps = {
  formId?: number
  viewMode?: boolean
  duplicate?: boolean
  newForm?: boolean
}

export const FormPage = ({
  formId,
  viewMode,
  duplicate,
  newForm,
}: FormPageProps) => {
  const { data: form, isLoading } = useQuery(
    getApplicationFormQuery(newForm ? undefined : formId),
  )

  return !isLoading ? (
    <FormForm form={form} viewMode={viewMode} duplicate={duplicate} />
  ) : (
    <Skeleton className="h-[100vh]" />
  )
}
