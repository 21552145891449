import { client } from "./client"
import { FileUploadFolder } from "@src/utils/consts"

export type UploadFileData = {
  file: File
  isBigFile?: boolean
  folder?: FileUploadFolder
}

export const uploadFile = async ({
  file,
  isBigFile,
  folder,
}: UploadFileData): Promise<string> => {
  const formData = new FormData()
  formData.append("file", file)

  if (folder) {
    formData.append("folder", folder)
  }

  const query: Record<string, string | number | boolean> = {}

  if (isBigFile) {
    query.isBigFile = true
  }

  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    params: query,
  }
  const { data } = await client.axiosInstance.post(
    "/api/File/Upload",
    formData,
    config,
  )
  return data
}
