import { t } from "@lingui/macro"

import {
  FormQuestionAnswerType,
  FormQuestionConditionLogic,
} from "@src/api/form"

export const getAnswerTypes = () => [
  {
    id: FormQuestionAnswerType.ShortTextAnswer,
    label: t`Short text`,
    icon: "short_text",
  },
  {
    id: FormQuestionAnswerType.LongTextAnswer,
    label: t`Long text`,
    icon: "subject",
  },
  {
    id: FormQuestionAnswerType.CountryAnswer,
    label: t`Countries`,
    icon: "globe",
  },
  {
    id: FormQuestionAnswerType.RadioChoiceAnswer,
    label: t`Radio Button`,
    group: "B",
    icon: "radio_button_checked",
  },
  {
    id: FormQuestionAnswerType.CheckBoxChoiceAnswer,
    label: t`Checkbox`,
    group: "B",
    icon: "check_box",
  },
  {
    id: FormQuestionAnswerType.MultipleChoiceAnswer,
    label: t`Multiple choice`,
    group: "B",
    icon: "list",
  },
  {
    id: FormQuestionAnswerType.TableAnswer,
    label: t`Table`,
    group: "B",
    icon: "table_rows",
  },
  {
    id: FormQuestionAnswerType.DropDownChoiceAnswer,
    label: t`Dropdown`,
    group: "B",
    icon: "arrow_drop_down",
  },

  {
    id: FormQuestionAnswerType.FileAnswer,
    label: t`File upload`,
    group: "C",
    icon: "cloud_upload",
  },
  {
    id: FormQuestionAnswerType.ScaleAnswer,
    label: t`Scale`,
    group: "C",
    icon: "linear_scale",
  },
  {
    id: FormQuestionAnswerType.CalendarAnswer,
    label: t`Calendar date`,
    group: "C",
    icon: "calendar_today",
  },
  {
    id: FormQuestionAnswerType.AccountMoneyAnswer,
    label: t`Account money`,
    group: "C",
    icon: "euro_symbol",
  },
]

export const getConditionLogicTypes = (answerType?: FormQuestionAnswerType) => {
  const baseLogicTypes = [
    {
      id: FormQuestionConditionLogic.Equal,
      label: t`Equals`,
    },
    {
      id: FormQuestionConditionLogic.NotEqual,
      label: t`Not equals`,
    },
    {
      id: FormQuestionConditionLogic.IsEmpty,
      label: t`Is empty`,
    },
    {
      id: FormQuestionConditionLogic.NotEmpty,
      label: t`Is not empty`,
    },
  ]

  switch (answerType) {
    case FormQuestionAnswerType.AccountMoneyAnswer:
    case FormQuestionAnswerType.ScaleAnswer:
      return [
        ...baseLogicTypes,
        {
          id: FormQuestionConditionLogic.GreaterThan,
          label: t`Greater than`,
        },
        {
          id: FormQuestionConditionLogic.LessThan,
          label: t`Less than`,
        },
      ]

    case FormQuestionAnswerType.LongTextAnswer:
    case FormQuestionAnswerType.ShortTextAnswer:
      return [
        ...baseLogicTypes,
        {
          id: FormQuestionConditionLogic.GreaterThan,
          label: t`Greater than`,
        },
        {
          id: FormQuestionConditionLogic.LessThan,
          label: t`Less than`,
        },
        {
          id: FormQuestionConditionLogic.Contains,
          label: t`Contains`,
        },
        {
          id: FormQuestionConditionLogic.DoesNotContain,
          label: t`Does not contain`,
        },
      ]

    default:
      return baseLogicTypes
  }
}

export const hasMultipleOptions = (type?: FormQuestionAnswerType) =>
  type !== undefined
    ? [
        FormQuestionAnswerType.RadioChoiceAnswer,
        FormQuestionAnswerType.CheckBoxChoiceAnswer,
        FormQuestionAnswerType.DropDownChoiceAnswer,
        FormQuestionAnswerType.MultipleChoiceAnswer,
        FormQuestionAnswerType.TableAnswer,
        FormQuestionAnswerType.DropDownChoiceAnswer,
      ].includes(type)
    : false

export const getOptionPlaceholder = (type?: FormQuestionAnswerType) => {
  switch (type) {
    case FormQuestionAnswerType.RadioChoiceAnswer:
      return t`Radio button option`
    case FormQuestionAnswerType.CheckBoxChoiceAnswer:
      return t`Checkbox option`
    case FormQuestionAnswerType.MultipleChoiceAnswer:
      return t`Multiple choice option`
    case FormQuestionAnswerType.TableAnswer:
      return t`Table row`
    case FormQuestionAnswerType.DropDownChoiceAnswer:
      return t`Dropdown option`
    default:
      return t`Option`
  }
}
