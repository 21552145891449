import { useQuery } from "@tanstack/react-query"

import { getCommunityApplicationDefinitionQuery } from "@src/api/application"

import { Skeleton } from "@src/components/atoms/Skeleton"
import { ApplicationDefinitionForm } from "./ApplicationDefinitionForm"

export type ApplicationDefinitionPageProps = {
  surveyId?: number
  newApplication?: boolean
  duplicate?: boolean
}

export const ApplicationDefinitionPage = ({
  surveyId,
  newApplication,
  duplicate,
}: ApplicationDefinitionPageProps) => {
  const { data: application, isLoading } = useQuery(
    getCommunityApplicationDefinitionQuery(
      newApplication ? undefined : surveyId,
    ),
  )

  return !isLoading ? (
    <ApplicationDefinitionForm
      application={application}
      duplicate={duplicate}
    />
  ) : (
    <Skeleton className="h-[100vh]" />
  )
}
