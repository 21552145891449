import { createFileRoute } from "@tanstack/react-router"
import { LoginPage } from "@src/components/pages/LoginPage"

export const Route = createFileRoute("/_unauth/login")({
  validateSearch: (search: Record<string, unknown>) => {
    return {
      redirect: (search.redirect as string) || null,
    }
  },
  component: LoginPageWrapper,
})

function LoginPageWrapper() {
  const search = Route.useSearch()
  return <LoginPage redirect={search.redirect} />
}
