import { useQuery } from "@tanstack/react-query"

import { getSurveyDefinitionQuery } from "@src/api/survey"

import { Skeleton } from "@src/components/atoms/Skeleton"
import { SurveyDefinitionForm } from "./SurveyDefinitionForm"

export type SurveyDefinitionPageProps = {
  surveyId?: number
  newSurvey?: boolean
  duplicate?: boolean
}

export const SurveyDefinitionPage = ({
  surveyId,
  newSurvey,
  duplicate,
}: SurveyDefinitionPageProps) => {
  const { data: survey, isLoading } = useQuery(
    getSurveyDefinitionQuery(newSurvey ? undefined : surveyId),
  )

  return !isLoading ? (
    <SurveyDefinitionForm survey={survey} duplicate={duplicate} />
  ) : (
    <Skeleton className="h-[100vh]" />
  )
}
