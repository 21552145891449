import { useEffect } from "react"
import { FormApi, ReactFormApi } from "@tanstack/react-form"
import { z } from "zod"
import { t, Trans } from "@lingui/macro"

import { Switch } from "@src/components/atoms/Switch"
import { ZodValidator } from "../"
import { Button } from "@src/components/atoms/Button"
import { Select } from "@src/components/atoms/Select"
import { Input } from "@src/components/atoms/Input"
import {
  FormQuestionConditionalLogicType,
  FormQuestionConditionLogic,
  EditorFormDto,
} from "@src/api/form"
import { DEFAULT_FORM_QUESTION_CONDITIONAL_LOGIC_OPTION } from "./defaultData"
import { getConditionLogicTypes, hasMultipleOptions } from "./utils"

type GenericFormQuestionProps = {
  index: number
  form: FormApi<EditorFormDto, ZodValidator> &
    ReactFormApi<EditorFormDto, ZodValidator>
}

export const GenericFormConditionalLogic = ({
  index,
  form,
}: GenericFormQuestionProps) => {
  const conditionalLogicList = form.useStore(
    (state) =>
      state.values?.questions?.[index].formQuestionConditionalLogicList,
  )

  const allQuestions = form.useStore((state) => state.values?.questions) || []
  // The question options used in the conditional logic select dropdown
  // Show only questions that have a title and are not the current question
  // New questions shouldn't be shown until they have a title
  const allQuestionOptions = allQuestions
    ?.filter(
      ({ title, questionOrder }) =>
        questionOrder !== index && (questionOrder || 0) < index && title,
    )
    ?.map(({ id, title, temporaryQuestionId }) => ({
      // Use temporary id for new questions not submitted yet to the backend
      // or the actual id for existing questions
      id: temporaryQuestionId || id || 0,
      label: title || "",
    }))

  const validators = {
    temporaryQuestionId: {
      onChange: z.number().min(1, t`This field is required`),
    },
    conditionLogic: {
      onChange: z.number().min(1, t`This field is required`),
    },
    formQuestionOptionId: {
      onChange: z.number().min(1, t`This field is required`),
    },
    customValue: {
      onBlur: z.string().min(1, t`This field is required`),
    },
  }

  useEffect(() => {
    if (!conditionalLogicList?.length) {
      form.setFieldValue(
        `questions[${index}].formQuestionConditionalLogicList`,
        [DEFAULT_FORM_QUESTION_CONDITIONAL_LOGIC_OPTION],
      )
    }
  }, [conditionalLogicList, form, index])

  return (
    <div key={index} className="relative flex flex-col gap-3">
      <form.Field
        name={`questions[${index}].conditionalLogicType`}
        children={(field) => (
          <Switch
            checked={
              field.state.value ===
              FormQuestionConditionalLogicType.AllConditionals
            }
            onChange={(checked) => {
              !checked
                ? field.handleChange(
                    FormQuestionConditionalLogicType.AnyConditional,
                  )
                : field.handleChange(
                    FormQuestionConditionalLogicType.AllConditionals,
                  )
            }}
            label={t`Choose whether any or all rules should be fulfilled`}
          />
        )}
      />
      <div className="flex flex-col gap-2">
        <form.Field
          name={`questions[${index}].formQuestionConditionalLogicList`}
          mode="array"
          children={(optionsField) => {
            return (
              <>
                {!optionsField.state.value?.length
                  ? ""
                  : optionsField.state.value?.map((_, i) => {
                      const currentQuestionId =
                        optionsField.state.value?.[i]?.temporaryQuestionId

                      const currentQuestion = allQuestions.find(
                        ({ id, temporaryQuestionId }) =>
                          id === currentQuestionId ||
                          temporaryQuestionId === currentQuestionId,
                      )

                      const logicTypes = getConditionLogicTypes(
                        currentQuestion?.answerType,
                      )

                      const hasOptions = hasMultipleOptions(
                        currentQuestion?.answerType,
                      )

                      const questionOptions =
                        currentQuestion?.questionOptions?.map(
                          ({ questionOption, questionOptionOrder }) => ({
                            id: questionOptionOrder || 0,
                            label: questionOption || "",
                          }),
                        ) || []
                      return (
                        <div key={i} className="relative flex gap-2">
                          <form.Field
                            name={`questions[${index}].formQuestionConditionalLogicList[${i}].temporaryQuestionId`}
                            validators={validators.temporaryQuestionId}
                            children={(field) => (
                              <Select
                                className="w-full"
                                error={field.state.meta.errors.join(", ")}
                                placeholder={
                                  allQuestionOptions.length
                                    ? t`Question`
                                    : t`No questions available`
                                }
                                variant="secondary"
                                onSelect={({ id }) => {
                                  field.handleChange(id)
                                }}
                                value={allQuestionOptions.find(
                                  ({ id }) => id === field.state.value,
                                )}
                                options={allQuestionOptions}
                                disabled={!allQuestionOptions.length}
                              />
                            )}
                          />
                          <form.Field
                            name={`questions[${index}].formQuestionConditionalLogicList[${i}].conditionLogic`}
                            validators={validators.conditionLogic}
                            children={(field) => (
                              <Select
                                className="w-full"
                                error={field.state.meta.errors.join(", ")}
                                placeholder={t`Logic`}
                                variant="secondary"
                                onSelect={({
                                  id,
                                }: {
                                  id: FormQuestionConditionLogic
                                }) => {
                                  field.handleChange(id)
                                }}
                                value={logicTypes.find(
                                  ({ id }) => id === field.state.value,
                                )}
                                options={logicTypes}
                              />
                            )}
                          />
                          {hasOptions ? (
                            <form.Field
                              name={`questions[${index}].formQuestionConditionalLogicList[${i}].formQuestionOptionId`}
                              validators={validators.formQuestionOptionId}
                              children={(field) => (
                                <Select
                                  className="w-full"
                                  error={field.state.meta.errors.join(", ")}
                                  placeholder={t`Logic`}
                                  variant="secondary"
                                  onSelect={({
                                    id,
                                  }: {
                                    id: FormQuestionConditionLogic
                                  }) => {
                                    field.handleChange(id)
                                  }}
                                  value={questionOptions.find(
                                    ({ id }) => id === field.state.value,
                                  )}
                                  options={questionOptions}
                                />
                              )}
                            />
                          ) : (
                            <form.Field
                              name={`questions[${index}].formQuestionConditionalLogicList[${i}].customValue`}
                              validators={validators.customValue}
                              children={(field) => (
                                <Input
                                  className="w-full"
                                  error={field.state.meta.errors.join(", ")}
                                  name="value"
                                  placeholder={t`Value`}
                                  variant="ternary"
                                  onChange={(value) => {
                                    field.handleChange(value as string)
                                  }}
                                  value={field.state.value}
                                  onBlur={field.handleBlur}
                                />
                              )}
                            />
                          )}
                        </div>
                      )
                    })}
                <Button
                  variant="text"
                  small
                  className="self-start"
                  onClick={() => {
                    const conditionalLogicList = form.getFieldValue(
                      `questions[${index}].formQuestionConditionalLogicList`,
                    )

                    form.setFieldValue(
                      `questions[${index}].formQuestionConditionalLogicList`,
                      Array.isArray(conditionalLogicList)
                        ? [
                            ...conditionalLogicList,
                            DEFAULT_FORM_QUESTION_CONDITIONAL_LOGIC_OPTION,
                          ]
                        : [DEFAULT_FORM_QUESTION_CONDITIONAL_LOGIC_OPTION],
                    )
                  }}
                >
                  <Trans>Add question</Trans>
                </Button>
              </>
            )
          }}
        />
      </div>
    </div>
  )
}
