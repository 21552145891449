import { useEffect } from "react"
import { useNavigate } from "@tanstack/react-router"
import { useAuth } from "@src/hooks"
import { LoginForm } from "../LoginForm"

export type LoginPageProps = {
  redirect: string | null
}

export const LoginPage = ({ redirect }: LoginPageProps) => {
  const { isAuthenticated } = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    if (isAuthenticated) {
      const navigateTo = redirect || "/"
      navigate({ to: navigateTo })
    }
  }, [isAuthenticated, navigate, redirect])

  return (
    <div className="m-11 flex w-full items-center justify-center">
      <LoginForm />
    </div>
  )
}
