import { t, Trans } from "@lingui/macro"

import { Textarea } from "@src/components/atoms/Textarea"
import { SectionProps } from "./types"

export const TrackingSection = ({ form }: SectionProps) => {
  return (
    <section className="flex w-full flex-col gap-6 rounded-xl border border-pearl-lighter bg-white p-6">
      <h5 className="font-medium">
        <Trans>Application user tracking</Trans>
      </h5>
      <form.Field
        name="trackApplicationSave"
        children={(field) => (
          <Textarea
            name="trackApplicationSave"
            variant="primary"
            label={t`Scripts and pixels for tracking application access`}
            small
            onChange={(e) => {
              field.handleChange(e.target.value)
            }}
            value={(field.state.value as string) || ""}
            onBlur={field.handleBlur}
          />
        )}
      />
      <form.Field
        name="trackApplicationSubmission"
        children={(field) => (
          <Textarea
            name="trackApplicationSubmission"
            variant="primary"
            label={t`Scripts and pixels for tracking saving applications`}
            small
            onChange={(e) => {
              field.handleChange(e.target.value)
            }}
            value={(field.state.value as string) || ""}
            onBlur={field.handleBlur}
          />
        )}
      />
      <form.Field
        name="trackUserAccess"
        children={(field) => (
          <Textarea
            name="trackUserAccess"
            variant="primary"
            label={t`Scripts and pixels for tracking application submissions`}
            small
            onChange={(e) => {
              field.handleChange(e.target.value)
            }}
            value={(field.state.value as string) || ""}
            onBlur={field.handleBlur}
          />
        )}
      />
    </section>
  )
}
